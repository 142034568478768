import Service from './service';

class CustomerService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getCustomers() {
    const response = await this.api
      .get(
        'customers',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCustomer(id) {
    const response = await this.api
      .get(
        `customers/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCustomer(name, website) {
    const data = {
      customer: {
        name,
        website,
      },
    };

    const response = await this.api
      .post(
        'customers',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCustomer(id, name, website) {
    const data = {
      customer: {
        name,
        website,
      },
    };

    const response = await this.api
      .put(
        `customers/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCustomer(id) {
    const response = await this.api
      .delete(
        `customers/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new CustomerService();
