import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';
import FormSelect from 'layouts/components/FormSelect';

function DefaultHospitalPreferenceCard({ ops }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.interviews' });

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={t('hospital-preferences')}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormSelect
                  label={t('hospitals')}
                  options={ops.hospitals}
                  // value={findSelectValue(ops.hospitals, c.country_id, i)}
                  // onChange={(event) => {
                  //   setValue('country_id', event.value, i);
                  // }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultHospitalPreferenceCard.propTypes = {
  ops: PropTypes.shape({
    hospitals: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};
export default DefaultHospitalPreferenceCard;
