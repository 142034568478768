import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Reducer functions
import { fetchPositionListing, setPositionListingEditing } from 'reducers/positionListingsSlice';
import { fetchCustomers } from 'reducers/customerSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import PositionInfo from '../components/PositionInfo';

function EditPositionListing() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(setPositionListingEditing(true));
    dispatch(fetchPositionListing({ id }));
    dispatch(fetchCustomers());
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PositionInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditPositionListing;
