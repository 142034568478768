import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';
// eslint-disable-next-line
import {
  fetchAddressTypes,
  fetchCitizenships,
  fetchClinicalExperiences,
  fetchCountries,
  fetchEducationLevels,
  fetchLanguageProficiencies,
  fetchLanguages,
  fetchRaces,
} from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { checkPermission, moveSubView } from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import BasicInfo from '../BasicInfo';
import ContactInfo from '../ContactInfo';
import PersonalInfo from '../PersonalInfo';
import SavePanel from '../SavePanel';
import EducationInfo from '../Education';
import ExperienceInfo from '../Experience';
import Documents from '../Documents';
import LanguageProficiency from '../LanguageProficiency';
import Agreement from '../Agreements';
import InfoValidation from '../InfoValidation';
import SimilarCandidatesWidget from '../SimilarCandidatesWidget';

const selector = (state) => ({
  editing: state.candidate.editing,
  similar: state.candidate.similar,
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
});

function Profile() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.profile' });
  const {
    editing,
    similar,
    currentUser,
    currentSubView,
    goToSubView,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(fetchCountries());
    dispatch(fetchCitizenships());
    dispatch(fetchRaces());
    dispatch(fetchAddressTypes());
    dispatch(fetchEducationLevels());
    dispatch(fetchClinicalExperiences());
    dispatch(fetchLanguageProficiencies());
  }, [dispatch]);

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetProfileTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('contact-info'):
        dispatch(setCurrentSubView('basic-info'));
        break;
      case t('personal-info'):
        dispatch(setCurrentSubView('personal-info'));
        break;
      case t('education'):
        dispatch(setCurrentSubView('education'));
        break;
      case t('work-experience'):
        dispatch(setCurrentSubView('experience'));
        break;
      case t('language-proficiency'):
        dispatch(setCurrentSubView('language'));
        break;
      case t('documents'):
        dispatch(setCurrentSubView('documents'));
        break;
      case t('agreements'):
        dispatch(setCurrentSubView('agreements'));
        break;
      default:
        dispatch(setCurrentSubView('basic-info'));
        break;
    }
  };

  if (checkPermission('CAPC', currentUser)) {
    tabs.push(t('contact-info'));
    views.push('basic-info');
  }

  if (checkPermission('CAPP', currentUser) && editing) {
    tabs.push(t('personal-info'));
    views.push('personal-info');
  }

  if (checkPermission('CAPE', currentUser) && editing) {
    tabs.push(t('education'));
    views.push('education');
  }

  if (checkPermission('CAPW', currentUser) && editing) {
    tabs.push(t('work-experience'));
    views.push('experience');
  }

  if (checkPermission('CAPL', currentUser) && editing) {
    tabs.push(t('language-proficiency'));
    views.push('language');
  }

  if (checkPermission('CAPD', currentUser) && editing) {
    tabs.push(t('documents'));
    views.push('documents');
  }

  if (checkPermission('CAPA', currentUser) && editing) {
    tabs.push(t('agreements'));
    views.push('agreements');
  }

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  useEffect(() => {
    setDefaultTab(views.indexOf(currentSubView) >= 0 ? views.indexOf(currentSubView) : 0);
  }, [currentSubView, views]);

  return (
    <div id="profile">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetProfileTabValue}
        defaultTabValue={defaultTab}
      />
      {currentSubView === 'basic-info' && (
        <div id="view">
          <Grid item xs={12}>
            <InfoValidation />
          </Grid>
          <Grid item xs={12}>
            <BasicInfo />
          </Grid>
          <Grid item xs={12}>
            <ContactInfo />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'personal-info' && (
        <div id="view">
          <Grid item xs={12}>
            <PersonalInfo />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'education' && (
        <div id="view">
          <Grid item xs={12}>
            <EducationInfo />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'experience' && (
        <div id="view">
          <Grid item xs={12}>
            <ExperienceInfo />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'language' && (
        <div id="view">
          <Grid item xs={12}>
            <LanguageProficiency />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'documents' && (
        <div id="view">
          <Grid item xs={12}>
            <Documents />
            <SavePanel />
          </Grid>
        </div>
      )}
      {currentSubView === 'agreements' && (
        <div id="view">
          <Grid item xs={12}>
            <Agreement />
            <SavePanel />
          </Grid>
        </div>
      )}
      {(!editing && similar.length > 0) && (
        <SimilarCandidatesWidget />
      )}
    </div>
  );
}

export default Profile;
