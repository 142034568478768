import {
  createCandidateLanguageProficiency,
  deleteCandidateLanguageProficiency,
  updateCandidateLanguageProficiency,
} from 'reducers/candidatesSlice';
import {
  invalidRecords, MissingDataWarning, modifiedRecords, NoChangesWarning,
} from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveLanguage = (t, dispatch, languages, candidateId) => {
  let isValid = true;

  isValid = invalidRecords(languages).length < 1;
  languages.forEach((l) => {
    isValid = !isValid ? false : invalidRecords(l.candidate_language_courses).length < 1;
  });

  if (isValid) {
    const langs = languages.filter((item) => item.changed === true);
    if (langs.length > 0) {
      langs.forEach((l) => {
        const data = {
          candidateId,
          candidateLanguageCourses: l.candidate_language_courses
            .map((c) => ({
              id: c.id,
              school_name: c.school_name,
              started: c.started,
              expected: c.expected,
              active: c.active,
              // eslint-disable-next-line no-underscore-dangle
              _destroy: c._destroy,
              candidate_language_course_progress_attributes: c.candidate_language_course_progress,
            })),
          candidateLanguageTests: modifiedRecords(l.candidate_language_tests),
          assessmentResult: l.assessment_result,
          assessmentDate: l.assessment_date,
          schoolName: l.school_name,
          languageProficiencyId: l.language_proficiency_id,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (l._destroy === true) {
          dispatch(deleteCandidateLanguageProficiency({ id: l.id, candidateId }));
        } else if (typeof l.id !== 'undefined') {
          data.id = l.id;
          dispatch(updateCandidateLanguageProficiency(data));
        } else {
          dispatch(createCandidateLanguageProficiency(data));
        }
      });
    } else {
      NoChangesWarning(t);
    }
  } else {
    MissingDataWarning(t);
  }
};
