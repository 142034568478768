import axios from 'axios';
import Service from './service';

class BsrService extends Service {
  bsrApi;

  constructor() {
    super();

    this.bsrApi = axios.create({
      baseURL: `${process.env.REACT_APP_BSR_API_URL}`,
    });

    this.bsrApi.interceptors.response.use(
      (response) => response,
      (error) => error,
    );
  }

  async getUsers(page, tags) {
    const response = await this.bsrApi
      .get(
        `v2/users?tags=${tags}&items_per_page=200&page=${page}`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  async getUser(id) {
    const response = await this.bsrApi
      .get(
        `v2/users/${id}`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  async getUserCourses(id, page) {
    const response = await this.bsrApi
      .get(
        `v2/users/${id}/courses?page=${page}`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  async getUserProgress(id, page) {
    const response = await this.bsrApi
      .get(
        `v2/users/${id}/progress?items_per_page=200&page=${page}`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  async getStageUsers() {
    const response = await this.api
      .get(
        'bsr-progress',
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async saveProgress() {
    const response = await this.api
      .post(
        'bsr-progress/save',
        {},
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async fetchData() {
    const response = await this.api
      .post(
        'bsr-progress/fetch',
        {},
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async fetchJobs() {
    const response = await this.api
      .post(
        'bsr-progress/jobs',
        {},
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getCourses(page) {
    const response = await this.bsrApi
      .get(
        `v2/courses?page=${page}`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  async getCourseUsers(id, page) {
    const response = await this.bsrApi
      .get(
        `v2/courses/${id}/users?page=${page}&items_per_page=200`,
        { headers: this.getBsrHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  getBsrHeaders() {
    return {
      'Lw-Client': `${process.env.REACT_APP_BSR_CLIENT_ID}`,
      Authorization: `Bearer ${process.env.REACT_APP_BSR_TOKEN}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }
}

export default new BsrService();
