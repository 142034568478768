import {
  createCandidatePersonal,
  updateCandidatePersonal,
} from 'reducers/candidatesSlice';
import { MissingDataWarning, NoChangesWarning } from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const savePersonalInfo = (t, dispatch, personal, candidateId) => {
  if (personal.changed) {
    const isValid = (personal.weight !== '' && personal.weight !== undefined)
      && (personal.height !== '' && personal.height !== undefined);

    if (isValid) {
      const data = {
        candidateId,
        weight: personal.weight,
        height: personal.height,
        hairColor: personal.hair_color,
        eyeColor: personal.eye_color,
        raceId: personal.race_id,
        covidVaccine: personal.covid_vaccine,
        willingCovidVaccine: personal.willing_covid_vaccine,
        willingInfluenzaVaccine: personal.willing_influenza_vaccine,
        immigrationIssues: personal.immigration_issues,
        immigrationIssuesDetail: personal.immigration_issues_detail,
        countryId: personal.country_id,
        passportDateIssue: personal.passport_date_issue,
        passportDateExpiration: personal.passport_date_expiration,
        passportNumber: personal.passport_number,
        currentlyInUs: personal.currently_in_us,
        currentImmigrationStatus: personal.current_immigration_status,
        asylumApproved: personal.asylum_approved,
        issuedEad: personal.issued_ead,
        issuedSsn: personal.issued_ssn,
        legallyMarried: personal.legally_married,
        spouseName: personal.spouse_name,
        spouseDob: personal.spouse_dob,
        children: personal.children,
      };

      if (typeof personal.id !== 'undefined') {
        data.id = personal.id;
        dispatch(updateCandidatePersonal(data));
      } else {
        dispatch(createCandidatePersonal(data));
      }
    } else {
      MissingDataWarning(t);
    }
  } else {
    NoChangesWarning(t);
  }
};
