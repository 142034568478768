// react components
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { fetchCitiesIndex, fetchStatesIndex } from 'reducers/commonSlice';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import FormField from 'layouts/components/FormField';
import FormCheckbox from 'layouts/components/FormCheckbox';
import FormSelect from 'layouts/components/FormSelect';
import FormPhoneInput from 'layouts/components/FormPhoneInput';
import SelectionList from 'layouts/components/Lists/SelectionList';

// Functions
import { defaultValue, findSelectValue } from 'Util';

const selector = (state) => ({
  statesIndex: state.common.statesIndex,
  citiesIndex: state.common.citiesIndex,
});

function DefaultExperienceCard(
  {
    i,
    e,
    ops,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.experience' });
  const {
    statesIndex,
    citiesIndex,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);
  const [states, setStates] = useState(undefined);
  const [cities, setCities] = useState(undefined);
  const [clinicalExperiences, setClinicalExperiences] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };
  const handleCountry = (ev) => {
    if (ev.value !== undefined) {
      dispatch(
        fetchStatesIndex({
          id: ev.value,
          index: i,
        }),
      );

      setValue('country_id', ev.value, i);
    }
  };
  const handleState = (ev) => {
    if (ev.value !== undefined && ev.value !== null) {
      dispatch(
        fetchCitiesIndex({
          countryId: e.country_id,
          stateId: ev.value,
          index: i,
        }),
      );

      setValue('state_id', ev.value, i);
    }
  };

  const handleAddClinicalExp = (evn, value) => {
    evn.preventDefault();

    const exp = ops.clinicalExperiences.filter((c) => c.id === value)[0];

    setValue(
      'clinical_experiences',
      e.clinical_experiences.concat(
        {
          id: undefined,
          clinical_experience_id: exp.id,
          name: exp.name,
          value: exp.value,
        },
      ),
      i,
    );
  };

  const handleRemoveClinicalExp = (evn, value) => {
    evn.preventDefault();

    const idx = e.clinical_experiences.findIndex((ce) => ce.id === value);

    if (e.clinical_experiences[idx].id !== undefined) {
      setValue(
        'clinical_experiences',
        e.clinical_experiences.map((obj, index) => {
          if (index === idx) {
            return {
              ...obj,
              _destroy: true,
              destroy: true,
            };
          }
          return {
            ...obj,
          };
        }),
        i,
      );
    } else {
      setValue(
        'clinical_experiences',
        [...e.clinical_experiences.slice(0, idx), ...e.clinical_experiences.slice(idx + 1)],
        i,
      );
    }
  };

  useEffect(() => {
    if (e.country_id !== undefined && e.country_id !== null) {
      dispatch(
        fetchStatesIndex({
          id: e.country_id,
          index: i,
        }),
      );
    }

    if (e.state_id !== undefined && e.state_id !== null) {
      dispatch(
        fetchCitiesIndex({
          countryId: e.country_id,
          stateId: e.state_id,
          index: i,
        }),
      );
    }

    if (e.clinical_experiences !== undefined && e.clinical_experiences !== null) {
      setClinicalExperiences(e.clinical_experiences);
    }
  }, [dispatch, e, i]);

  useEffect(() => {
    setStates(statesIndex[i]);
  }, [dispatch, statesIndex, i]);

  useEffect(() => {
    setCities(citiesIndex[i]);
  }, [dispatch, citiesIndex, i]);

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={e.company !== '' ? e.company : `${t('experience')} ${i + 1}`}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField
                  label={t('company-hospital')}
                  error={!e.company || e.company === ''}
                  onChange={(event) => {
                    setValue('company', event.target.value, i);
                  }}
                  value={defaultValue(e.company)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label={t('job-title')}
                  error={!e.job_title || e.job_title === ''}
                  onChange={(event) => {
                    setValue('job_title', event.target.value, i);
                  }}
                  value={defaultValue(e.job_title)}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormField
                  label={t('from')}
                  type="date"
                  placeholder="From"
                  error={!e.from || e.from === ''}
                  onChange={(event) => {
                    setValue('from', event.target.value, i);
                  }}
                  value={defaultValue(e.from)}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormField
                  label={t('to')}
                  type="date"
                  placeholder="To"
                  error={e.current ? false : !e.to || e.to === ''}
                  onChange={(event) => {
                    setValue('to', event.target.value, i);
                  }}
                  value={defaultValue(e.to)}
                  disabled={e.current}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormCheckbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  checked={e.current}
                  onChange={(event) => {
                    setValue('current', event.target.checked, i);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label={t('current')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label={t('address')}
                  onChange={(event) => {
                    setValue('address', event.target.value, i);
                  }}
                  value={defaultValue(e.address)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormSelect
                  label={t('country')}
                  options={ops.countries}
                  value={findSelectValue(ops.countries, e.country_id)}
                  onChange={(event) => {
                    handleCountry(event);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormSelect
                  label={t('state-province')}
                  options={states}
                  id={`state[${i}]`}
                  value={
                    states !== undefined
                      ? findSelectValue(states, e.state_id, i)
                      : undefined
                  }
                  onChange={(event) => {
                    handleState(event, i);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormSelect
                  label={t('city')}
                  options={cities}
                  id={`city[${i}]`}
                  value={
                    cities !== undefined ? findSelectValue(cities, e.city_id, i) : undefined
                  }
                  onChange={(event) => {
                    setValue('city_id', event.value, i);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormField
                  label={t('postal-code')}
                  onChange={(event) => {
                    setValue('postal_code', event.target.value, i);
                  }}
                  value={defaultValue(e.postal_code)}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectionList
                  title={t('clinical-experience')}
                  options={ops.clinicalExperiences}
                  selection={clinicalExperiences.filter((ce) => ce.destroy === undefined)}
                  removeFunc={(event, selectedOption) => {
                    handleRemoveClinicalExp(event, selectedOption, i);
                  }}
                  addFunc={(event, selectedOption) => {
                    handleAddClinicalExp(event, selectedOption, i);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label={t('work-performed')}
                  onChange={(event) => {
                    setValue('work_type', event.target.value, i);
                  }}
                  value={defaultValue(e.work_type)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label={t('supervisor-name')}
                  onChange={(event) => {
                    setValue('supervisor_name', event.target.value, i);
                  }}
                  value={defaultValue(e.supervisor_name)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label={t('supervisor-title')}
                  onChange={(event) => {
                    setValue('supervisor_title', event.target.value, i);
                  }}
                  value={defaultValue(e.supervisor_title)}
                />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <FormField
                  label={t('supervisor-e-mail')}
                  onChange={(event) => {
                    setValue('supervisor_email', event.target.value, i);
                  }}
                  value={defaultValue(e.supervisor_email)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormPhoneInput
                  label={t('supervisor-phone')}
                  // eslint-disable-next-line react/jsx-curly-brace-presence
                  country={'us'}
                  placeholder=""
                  specialLabel=""
                  enableSearch
                  value={e.supervisor_phone}
                  onChange={(value) => {
                    setValue('supervisor_phone', value, i);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultExperienceCard.propTypes = {
  i: PropTypes.number.isRequired,
  e: PropTypes.shape(
    {
      address: PropTypes.string,
      city_id: PropTypes.number,
      company: PropTypes.string,
      country_id: PropTypes.number,
      current: PropTypes.bool,
      clinical_experiences: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          value: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
      from: PropTypes.string,
      id: PropTypes.number,
      job_title: PropTypes.string,
      postal_code: PropTypes.string,
      state_id: PropTypes.number,
      supervisor_email: PropTypes.string,
      supervisor_name: PropTypes.string,
      supervisor_phone: PropTypes.string,
      supervisor_title: PropTypes.string,
      to: PropTypes.string,
      work_type: PropTypes.string,
    },
  ).isRequired,
  ops: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    clinicalExperiences: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultExperienceCard;
