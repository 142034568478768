import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AccordionActions } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { checkPermission, setTitle } from 'Util';

// Components
import Overview from './components/Overview';
import BsrUsers from './components/BsrUsers';
import BsrUsersParameters from './components/Parameters/BsrUsersParams';
import Progress from './components/Progress';
import ProgressParameters from './components/Parameters/ProgressParameters';
import DataViewer from './components/DataViewer';
import Summaries from './components/Summaries';
import BsrCoursesEnrollment from './components/BsrCoursesEnrollment';
import BsrCoursesParameters from './components/Parameters/BsrCoursesParameters';
import CandidatesLatestStatuses from './components/CandidatesLatestStatuses';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function CandidatesReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [parameters, setParameters] = useState({});
  const [preRunParams, setPreRunParams] = useState({});
  const [selected, setSelected] = useState({ value: '' });
  const [showParameters, setShowParameters] = useState(false);
  const [openParameters, setOpenParameters] = useState(false);
  const [showCandidateStatuses, setShowCandidateStatuses] = useState(false);

  useEffect(() => {
    switch (selected.value) {
      case 'progress':
        setParameters({ statuses: [2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17] });
        setShowCandidateStatuses(true);
        setShowParameters(true);
        break;
      case 'bsr-users':
        setParameters({ tags: ['NASH'] });
        setShowParameters(true);
        break;
      case 'bsr-courses':
        setShowParameters(true);
        break;
      default:
        setShowCandidateStatuses(false);
        setShowParameters(false);
    }
  }, [selected, dispatch]);

  useEffect(() => {
    if (showCandidateStatuses) {
      dispatch(fetchCandidateStatuses());
    }
  }, [dispatch, showCandidateStatuses]);

  setTitle('Candidates Reports');

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R001', currentUser)) {
      r.push({
        value: 'overview',
        label: t('overview'),
      });
    }
    if (checkPermission('R002', currentUser)) {
      r.push({
        value: 'progress',
        label: t('progress'),
      });
    }
    if (checkPermission('R003', currentUser)) {
      r.push({
        value: 'dataview',
        label: t('data-viewer'),
      });
    }
    if (checkPermission('R004', currentUser)) {
      r.push({
        value: 'bsr-users',
        label: t('bsr-users'),
      });
    }
    if (checkPermission('R005', currentUser)) {
      r.push({
        value: 'bsr-courses',
        label: t('bsr-courses'),
      });
    }
    if (checkPermission('R006', currentUser)) {
      r.push({
        value: 'summary',
        label: t('summary'),
      });
    }
    if (checkPermission('R007', currentUser)) {
      r.push({
        value: 'candidates-statuses',
        label: t('candidates-statuses'),
      });
    }

    return r;
  }, [currentUser, t]);

  const handleSelectReport = (rep) => {
    setSelected(rep);
  };

  const handleSaveParameters = () => {
    setOpenParameters(false);
    setParameters(preRunParams);
  };

  const handleSetParameters = (params) => {
    setPreRunParams(params);
  };

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={3}>
          <FormSelect
            label={t('report')}
            value={selected}
            options={reports}
            onChange={handleSelectReport}
          />
        </Grid>
        <Grid item md={9}>
          {showParameters && (
            <Accordion
              expanded={openParameters}
              onChange={(e, expanded) => setOpenParameters(expanded)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="parameters"
                id="parameters"
              >
                <SuiTypography>{t('parameters')}</SuiTypography>
              </AccordionSummary>
              <AccordionDetails>
                {selected.value === 'progress' && (
                  <ProgressParameters
                    defaultParameters={parameters}
                    setParametersFunc={handleSetParameters}
                  />
                )}
                {selected.value === 'bsr-users' && (
                  <BsrUsersParameters
                    defaultParameters={parameters}
                    setParametersFunc={handleSetParameters}
                  />
                )}
                {selected.value === 'bsr-courses' && (
                  <BsrCoursesParameters setParametersFunc={handleSetParameters} />
                )}
              </AccordionDetails>
              <AccordionActions>
                <SuiButton
                  variant="text"
                  color="info"
                  onClick={handleSaveParameters}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SuiButton>
              </AccordionActions>
            </Accordion>
          )}
        </Grid>
        <Grid item md={12}>
          {selected.value === 'overview' && <Overview />}
          {selected.value === 'progress' && <Progress parameters={parameters} />}
          {selected.value === 'dataview' && <DataViewer />}
          {selected.value === 'bsr-users' && <BsrUsers parameters={parameters} />}
          {selected.value === 'bsr-courses' && <BsrCoursesEnrollment parameters={parameters} />}
          {selected.value === 'summary' && <Summaries />}
          {selected.value === 'candidates-statuses' && <CandidatesLatestStatuses />}
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default CandidatesReports;
