import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchCandidatePersonal,
  setCurrentSubView,
  setPersonal,
  updateCandidatePersonalPassport,
  updateCandidatePersonalSignature,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchRaces } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSignaturePad from 'layouts/components/FormSignaturePad';

// Functions
// eslint-disable-next-line
import { checkPermission, defaultValue, findSelectValue, getCandidateUUID, setTitle } from 'Util';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { DropzoneDialog } from 'material-ui-dropzone';
import dataURLtoBlob from 'blueimp-canvas-to-blob';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  personal: state.candidate.personal,
  races: state.common.races,
  countries: state.common.countries,
});

function PersonalInfo() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.personal-info',
  });
  const {
    currentUser,
    editing,
    personal,
    races,
    countries,
  } = useSelector(selector, shallowEqual);

  const lbsKgs = [
    {
      value: 'lbs',
      label: 'lbs',
    },
    {
      value: 'kg',
      label: 'kg',
    },
  ];

  const cmFt = [
    {
      value: 'ft',
      label: 'ft',
    },
    {
      value: 'cm',
      label: 'cm',
    },
  ];

  const hairColors = [
    {
      value: 'Black',
      label: t('black'),
    },
    {
      value: 'Medium Brown',
      label: t('medium-brown'),
    },
    {
      value: 'Light Brown',
      label: t('light-brown'),
    },
    {
      value: 'Brown',
      label: t('brown'),
    },
    {
      value: 'Light Blonde',
      label: t('light-blonde'),
    },
    {
      value: 'Medium Blonde',
      label: t('medium-blonde'),
    },
    {
      value: 'Blonde',
      label: t('blonde'),
    },
    {
      value: 'Other',
      label: t('other'),
    },
  ];

  const eyeColors = [
    {
      value: 'Amber',
      label: t('amber'),
    },
    {
      value: 'Blue',
      label: t('blue'),
    },
    {
      value: 'Brown',
      label: t('brown'),
    },
    {
      value: 'Gray',
      label: t('gray'),
    },
    {
      value: 'Green',
      label: t('green'),
    },
    {
      value: 'Hazel',
      label: t('hazel'),
    },
    {
      value: 'Black',
      label: t('black'),
    },
    {
      value: 'Other',
      label: t('other'),
    },
  ];

  const vaccineStatus = [
    {
      value: 'Yes',
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: 'No',
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const currentUsOptions = [
    {
      value: 'Yes',
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: 'No',
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const currentImmigrationStatus = [
    {
      value: 1,
      label: t('visa'),
    },
    {
      value: 2,
      label: t('asylum'),
    },
    {
      value: 3,
      label: t('no-status'),
    },
  ];

  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('personal-info'));
    dispatch(fetchRaces());
    dispatch(fetchCountries());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidatePersonal({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Personal Info');

  const [firstLoad, setFirstLoad] = useState(true);
  const [weightUnit, setWeightUnit] = useState({
    value: 'lbs',
    label: 'lbs',
  });
  const [weightDisplay, setWeightDisplay] = useState('');
  const [heightUnit, setHeightUnit] = useState({
    value: 'ft',
    label: 'ft',
  });
  const [heightDisplay, setHeightDisplay] = useState('');
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const closeUploadDropbox = (e) => {
    e.preventDefault();
    setOpenUpload(false);
  };

  const setWeightUnitValue = (e) => {
    setWeightUnit(e);
    if (e.value === 'lbs') {
      setWeightDisplay(Math.round(personal.weight * 100) / 100);
    } else {
      setWeightDisplay(Math.round(personal.weight * 45.3592) / 100);
    }
  };

  const calcHeightInFeet = (h) => {
    const inchesTotal = Math.round(h * 0.393701);
    const feet = Math.floor(inchesTotal / 12);
    const inches = inchesTotal % 12;

    return `${feet}' ${inches}"`;
  };
  const setHeightUnitValue = (e) => {
    setHeightUnit(e);
    if (e.value === 'cm') {
      setHeightDisplay(Math.round(personal.height * 100) / 100);
    } else {
      setHeightDisplay(calcHeightInFeet(personal.height));
    }
  };

  const setValue = (key, value) => {
    dispatch(
      setPersonal({
        ...personal,
        [key]: value,
        changed: true,
      }),
    );
  };

  const setChildrenValue = (key, value, i) => {
    dispatch(
      setPersonal({
        ...personal,
        children: personal.children.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
            };
          }
          return obj;
        }),
        changed: true,
      }),
    );
  };

  const setWeightValue = (value) => {
    setWeightDisplay(value);

    if (weightUnit.value === 'lbs') {
      setValue('weight', value);
    } else {
      setValue('weight', value / 0.453592);
    }
  };

  const setHeightValue = (value) => {
    if (heightUnit.value === 'cm') {
      setValue('height', value);
      setHeightDisplay(value);
    } else {
      const unformattedValue = value.replace('"', '').replace(' ', '').split('\'');
      let totalInches = 0;

      if (unformattedValue[0] !== undefined) {
        totalInches = ((unformattedValue[0] !== undefined ? unformattedValue[0] : 0) * 12)
          + ((unformattedValue[1] !== undefined ? unformattedValue[1] : 0) * 1);
      }

      setValue('height', Math.floor(totalInches * 2.54));
      setHeightDisplay(value);
    }
  };

  const saveSignature = (image) => {
    dispatch(
      updateCandidatePersonalSignature({
        id: personal.id,
        candidateId: uuid,
        file: image,
      }),
    );
  };

  const handlePassportChange = (image) => {
    setDropzoneOpen(false);
    dispatch(
      updateCandidatePersonalPassport({
        id: personal.id,
        candidateId: uuid,
        file: image[0],
      }),
    );
  };

  useEffect(() => {
    if (personal.weight !== undefined && firstLoad) {
      setWeightDisplay(personal.weight);
      setHeightDisplay(calcHeightInFeet(personal.height));
      setFirstLoad(false);
    }
  }, [personal, firstLoad]);

  const uploadDialog = () => {
    setOpenUpload(true);
  };

  const uploadSignature = (files) => {
    setOpenUpload(false);

    const reader = new FileReader();
    reader.onloadend = () => {
      saveSignature(dataURLtoBlob(reader.result));
    };
    reader.readAsDataURL(files[0]);
  };

  function calculateAge(date) {
    const dob = new Date(date);

    if (!(dob instanceof Date)) {
      throw new Error('Invalid date of birth. Please provide a Date object.');
    }

    const today = new Date();
    let ageYears = today.getFullYear() - dob.getFullYear();
    let ageMonths = today.getMonth() - dob.getMonth();

    if (ageMonths < 0) {
      ageYears -= 1;
      ageMonths += 12;
    }

    if (today.getDate() < dob.getDate()) {
      ageMonths -= 1;
      if (ageMonths < 0) {
        ageYears -= 1;
        ageMonths += 12;
      }
    }

    return `${t('age')}: ${ageYears} ${t('years')} ${t('and')} ${ageMonths} ${t('months')}`;
  }

  const handleAddChild = () => {
    const children = personal.children === undefined ? [] : personal.children;
    dispatch(
      setPersonal({
        ...personal,
        children: children.concat({
          id: undefined,
          name: '',
          dob: Date(),
        }),
        changed: true,
      }),
    );
  };

  const handleRemoveChild = (i) => {
    if (personal.children[i].id !== undefined) {
      dispatch(
        setPersonal({
          ...personal,
          children: personal.children.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                _destroy: true,
              };
            }
            return obj;
          }),
          changed: true,
        }),
      );
    } else {
      dispatch(
        setPersonal({
          ...personal,
          children: [...personal.children.slice(0, i), ...personal.children.slice(i + 1)],
          changed: true,
        }),
      );
    }
  };

  return (
    <div>
      {checkPermission('CAPI', currentUser) && (
        <>
          <Card id="personal-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('personal-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                {checkPermission('CAIW', currentUser) && (
                  <>
                    <Grid item md={1}>
                      <FormField
                        label={t('weight')}
                        textTransform="none"
                        value={defaultValue(weightDisplay)}
                        onChange={(e) => {
                          setWeightValue(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <FormSelect
                        options={lbsKgs}
                        value={defaultValue(weightUnit)}
                        onChange={setWeightUnitValue}
                      />
                    </Grid>
                  </>
                )}
                {checkPermission('CAIH', currentUser) && (
                  <>
                    <Grid item md={1}>
                      <FormField
                        label={t('height')}
                        textTransform="none"
                        value={defaultValue(heightDisplay)}
                        onChange={(e) => {
                          setHeightValue(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <FormSelect
                        options={cmFt}
                        value={defaultValue(heightUnit)}
                        onChange={setHeightUnitValue}
                      />
                    </Grid>
                  </>
                )}
                {checkPermission('CAIC', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('hair-color')}
                      textTransform="none"
                      options={hairColors}
                      value={findSelectValue(hairColors, personal.hair_color)}
                      onChange={(e) => {
                        setValue('hair_color', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIE', currentUser) && (
                  <Grid item md={2}>
                    <FormSelect
                      label={t('eye-color')}
                      textTransform="none"
                      options={eyeColors}
                      value={findSelectValue(eyeColors, personal.eye_color)}
                      onChange={(e) => {
                        setValue('eye_color', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIR', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('race')}
                      textTransform="none"
                      options={races}
                      value={findSelectValue(races, personal.race_id)}
                      onChange={(e) => {
                        setValue('race_id', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIV1', currentUser) && (
                  <Grid item md={2}>
                    <FormSelect
                      label={t('covid-vaccine')}
                      textTransform="none"
                      options={vaccineStatus}
                      value={findSelectValue(vaccineStatus, personal.covid_vaccine)}
                      onChange={(e) => {
                        setValue('covid_vaccine', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIVW', currentUser) && (
                  <>
                    <Grid item md={3} mt={3}>
                      <SuiTypography fontWeight="regular">
                        {`${t('willing-vaccine-covid')}:`}
                      </SuiTypography>
                    </Grid>
                    <Grid item md={1}>
                      {personal.covid_vaccine === 'No' && (
                        <FormSwitch
                          label={
                            personal.willing_covid_vaccine
                              ? t('yes', { keyPrefix: 'common' })
                              : t('no', { keyPrefix: 'common' })
                          }
                          textTransform="none"
                          checked={
                            personal.willing_covid_vaccine !== undefined
                              ? personal.willing_covid_vaccine
                              : false
                          }
                          onChange={(event) => {
                            setValue('willing_covid_vaccine', event.target.checked);
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}
                {checkPermission('CAIV2', currentUser) && (
                  <>
                    <Grid item md={3} mt={3}>
                      <SuiTypography fontWeight="regular">
                        {`${t('willing-vaccine-influenza')}:`}
                      </SuiTypography>
                    </Grid>
                    <Grid item md={1}>
                      {personal.covid_vaccine === 'No' && (
                        <FormSwitch
                          label={
                            personal.willing_influenza_vaccine
                              ? t('yes', { keyPrefix: 'common' })
                              : t('no', { keyPrefix: 'common' })
                          }
                          textTransform="none"
                          checked={
                            personal.willing_influenza_vaccine !== undefined
                              ? personal.willing_influenza_vaccine
                              : false
                          }
                          onChange={(event) => {
                            setValue('willing_influenza_vaccine', event.target.checked);
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item md={2} />
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAIM', currentUser) && (
        <>
          <Card id="immigration-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('immigration-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item md={2}>
                  <FormSelect
                    label={t('currently-in-us')}
                    textTransform="none"
                    options={currentUsOptions}
                    value={findSelectValue(currentUsOptions, personal.currently_in_us)}
                    onChange={(e) => {
                      setValue('currently_in_us', e.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormSelect
                    label={t('current-immigration-status')}
                    textTransform="none"
                    options={currentImmigrationStatus}
                    value={findSelectValue(
                      currentImmigrationStatus,
                      personal.current_immigration_status,
                    )}
                    onChange={(e) => {
                      setValue('current_immigration_status', e.value);
                    }}
                  />
                </Grid>
                {personal.current_immigration_status === 2 && (
                  <>
                    <Grid item md={2} mt={3}>
                      <SuiTypography fontWeight="regular">
                        {`${t('asylum-approved')}:`}
                      </SuiTypography>
                    </Grid>
                    <Grid item md={1}>
                      <FormSwitch
                        label={
                          personal.asylum_approved
                            ? t('yes', { keyPrefix: 'common' })
                            : t('no', { keyPrefix: 'common' })
                        }
                        textTransform="none"
                        checked={
                          personal.asylum_approved !== undefined
                            ? personal.asylum_approved
                            : false
                        }
                        onChange={(event) => {
                          setValue('asylum_approved', event.target.checked);
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={1} mt={3}>
                  <SuiTypography fontWeight="regular">
                    {`${t('issued-ead')}:`}
                  </SuiTypography>
                </Grid>
                <Grid item md={1}>
                  <FormSwitch
                    label={
                      personal.issued_ead
                        ? t('yes', { keyPrefix: 'common' })
                        : t('no', { keyPrefix: 'common' })
                    }
                    textTransform="none"
                    checked={
                      personal.issued_ead !== undefined
                        ? personal.issued_ead
                        : false
                    }
                    onChange={(event) => {
                      setValue('issued_ead', event.target.checked);
                    }}
                  />
                </Grid>
                <Grid item md={1} mt={3}>
                  <SuiTypography fontWeight="regular">
                    {`${t('issued-ssn')}:`}
                  </SuiTypography>
                </Grid>
                <Grid item md={1}>
                  <FormSwitch
                    label={
                      personal.issued_ssn
                        ? t('yes', { keyPrefix: 'common' })
                        : t('no', { keyPrefix: 'common' })
                    }
                    textTransform="none"
                    checked={
                      personal.issued_ssn !== undefined
                        ? personal.issued_ssn
                        : false
                    }
                    onChange={(event) => {
                      setValue('issued_ssn', event.target.checked);
                    }}
                  />
                </Grid>
                {personal.current_immigration_status !== 2 && (
                  <Grid item md={3} />
                )}
                {checkPermission('CAII', currentUser) && (
                  <>
                    <Grid item md={4} mt={3}>
                      <SuiTypography fontWeight="regular">
                        {`${t('immigration-issues')}:`}
                      </SuiTypography>
                    </Grid>
                    <Grid item md={1}>
                      <FormSwitch
                        label={
                          personal.immigration_issues
                            ? t('yes', { keyPrefix: 'common' })
                            : t('no', { keyPrefix: 'common' })
                        }
                        textTransform="none"
                        checked={
                          personal.immigration_issues !== undefined
                            ? personal.immigration_issues
                            : false
                        }
                        onChange={(event) => {
                          setValue('immigration_issues', event.target.checked);
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={12}>
                  {personal.immigration_issues && (
                    <div className="document-editor">
                      <div className="document-editor__toolbar" />
                      <CKEditor
                        editor={DecoupledEditor}
                        data={personal.immigration_issues_detail}
                        onReady={(editor) => {
                          window.editor = editor;
                          if (document.querySelector('.document-editor__toolbar') !== undefined
                            && document.querySelector('.document-editor__toolbar') !== null) {
                            const toolbarContainer = document.querySelector('.document-editor__toolbar');
                            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                          }
                        }}
                        onChange={(event, editor) => {
                          if (personal.immigration_issues_detail !== undefined) {
                            setValue('immigration_issues_detail', editor.getData());
                          }
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAFI', currentUser) && (
        <>
          <Card id="family-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('family-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item md={2}>
                  <FormSelect
                    label={t('legally-married')}
                    textTransform="none"
                    options={currentUsOptions}
                    value={findSelectValue(currentUsOptions, personal.legally_married)}
                    onChange={(e) => {
                      setValue('legally_married', e.value);
                    }}
                  />
                </Grid>
                <Grid item md={8}>
                  <FormField
                    label={t('spouse-name')}
                    value={personal.spouse_name}
                    onChange={(e) => {
                      setValue('spouse_name', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormField
                    label={t('dob')}
                    type="date"
                    value={personal.spouse_dob}
                    onChange={(e) => {
                      setValue('spouse_dob', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={handleAddChild}
                      >
                        {t('add-child')}
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
                {(personal.children !== undefined && personal.children !== null)
                  // eslint-disable-next-line no-underscore-dangle
                  && personal.children.filter((i) => i._destroy !== true).map((c, i) => (
                    <>
                      <Grid item md={6}>
                        <FormField
                          label={t('name')}
                          value={c.name}
                          onChange={(e) => {
                            setChildrenValue('name', e.target.value, i);
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormField
                          label={t('dob')}
                          type="date"
                          value={c.dob}
                          onChange={(e) => {
                            setChildrenValue('dob', e.target.value, i);
                          }}
                        />
                      </Grid>
                      <Grid item md={1} mt={4}>
                        <CancelOutlinedIcon
                          color="error"
                          onClick={() => {
                            handleRemoveChild(i);
                          }}
                        />
                      </Grid>
                      <Grid item md={3} mt={3}>
                        {calculateAge(c.dob)}
                      </Grid>
                    </>
                  ))}
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAIS', currentUser) && (
        <>
          <Card id="signature-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('signature')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={6}>
                  {personal.id !== undefined && (
                    <FormSignaturePad
                      label={t('signature')}
                      image={personal.signature_url}
                      saveFunc={saveSignature}
                      clearLabel={t('clear')}
                      saveLabel={t('save')}
                      cancelLabel={t('cancel')}
                      shadow="lg"
                      borderRadius="xl"
                      height={200}
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={6}>
                  <SuiButton onClick={uploadDialog}>{t('upload')}</SuiButton>
                  <DropzoneDialog
                    acceptedFiles={[
                      'image/jpeg',
                      'image/png',
                      'image/tiff',
                      'image/webp',
                    ]}
                    cancelButtonText={t('cancel')}
                    submitButtonText={t('upload')}
                    maxFileSize={10000000}
                    filesLimit={1}
                    open={openUpload}
                    onClose={closeUploadDropbox}
                    onSave={(files) => {
                      uploadSignature(files);
                    }}
                    showPreviews
                    showFileNamesInPreview
                  />
                </Grid>
                <Grid item xs={12} md={3} />
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAIP', currentUser) && (
        <>
          <Card id="passport-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('passport-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <FormSelect
                    label={t('issuing-country')}
                    options={countries}
                    value={findSelectValue(countries, personal.country_id)}
                    textTransform="none"
                    onChange={(e) => {
                      setValue('country_id', e.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('date-of-issue')}
                    type="date"
                    textTransform="none"
                    value={defaultValue(personal.passport_date_issue)}
                    onChange={(e) => {
                      setValue('passport_date_issue', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('date-of-expiration')}
                    type="date"
                    textTransform="none"
                    value={defaultValue(personal.passport_date_expiration)}
                    onChange={(e) => {
                      setValue('passport_date_expiration', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('passport-number')}
                    textTransform="none"
                    value={defaultValue(personal.passport_number)}
                    onChange={(e) => {
                      setValue('passport_number', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="passport-content"
                      id="passport"
                    >
                      <SuiTypography>{t('passport')}</SuiTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SuiBox
                        component="img"
                        src={personal.passport_url}
                        shadow="lg"
                        borderRadius="xl"
                        width="100%"
                      />
                      <SuiButton variant="contained" color="secondary" onClick={() => setDropzoneOpen(true)}>
                        {t('add-passport')}
                      </SuiButton>
                      <DropzoneDialog
                        acceptedFiles={['image/*']}
                        cancelButtonText="cancel"
                        submitButtonText="submit"
                        maxFileSize={10000000}
                        filesLimit={1}
                        open={dropzoneOpen}
                        onClose={() => setDropzoneOpen(false)}
                        onSave={(image) => {
                          handlePassportChange(image);
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
    </div>
  );
}

export default PersonalInfo;
