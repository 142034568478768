import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  assignCandidateExperienceDocument,
  deleteCandidateExperienceDocument,
  fetchCandidateExperienceDocuments,
  fetchCandidateExperiences,
  setCurrentSubView,
  setExperience,
  setRemoveData,
  uploadCandidateExperienceDocument,
} from 'reducers/candidatesSlice';
import {
  fetchCitiesIndex,
  fetchClinicalExperiences,
  fetchCountries,
  fetchStatesIndex,
} from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import Card from '@mui/material/Card';

// Components
import ExperienceCard from 'layouts/components/Cards/ExperienceCards/DefaultExperienceCard';
import FormSelect from 'layouts/components/FormSelect';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Other components
import 'assets/styles/phone-input.css';

// Functions
import { getCandidateUUID, setTitle } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  experience: state.candidate.experience,
  removeData: state.candidate.removeData,
  countries: state.common.countries,
  statesIndex: state.common.statesIndex,
  citiesIndex: state.common.citiesIndex,
  clinicalExperiences: state.common.clinicalExperiences,
  documents: state.document.documents,
  experienceDocuments: state.candidate.experienceDocuments,
});

function ExperienceInfo() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.experience',
  });
  const {
    editing,
    experience,
    removeData,
    countries,
    statesIndex,
    citiesIndex,
    clinicalExperiences,
    documents,
    experienceDocuments,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('experience'));
    dispatch(fetchCountries());
    dispatch(fetchClinicalExperiences());
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateExperiences({ uuid }));
      dispatch(fetchCandidateExperienceDocuments({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Work Experience');

  const [firstLoad, setFirstLoad] = useState(true);
  const [experienceStates, setExperienceStates] = useState([]);
  const [experienceCities, setExperienceCities] = useState([]);
  const [documentMenu, setDocumentMenu] = useState(null);
  const [expandedDocumentState, setExpandedDocumentState] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availExperienceDocs, setAvailExperienceDocs] = useState([]);

  const openDocumentMenu = (event) => setDocumentMenu(event.currentTarget);
  const closeDocumentMenu = () => setDocumentMenu(null);
  const handleExpand = () => {
    closeDocumentMenu();
    setExpandedDocumentState(!expandedDocumentState);
  };

  const isValid = (key, value, r) => {
    switch (key) {
      case 'company':
        return (
          value !== ''
          && value !== undefined
          && r.job_title !== ''
          && r.job_title !== undefined
          && r.from !== ''
          && r.from !== undefined
          && ((r.to !== '' && r.to !== undefined) || r.current)
        );
      case 'job_title':
        return (
          value !== ''
          && value !== undefined
          && r.company !== ''
          && r.company !== undefined
          && r.from !== ''
          && r.from !== undefined
          && ((r.to !== '' && r.to !== undefined) || r.current)
        );
      case 'from':
        return (
          value !== ''
          && value !== undefined
          && r.company !== ''
          && r.company !== undefined
          && r.job_title !== ''
          && r.job_title !== undefined
          && ((r.to !== '' && r.to !== undefined) || r.current)
        );
      case 'to':
        return (
          ((value !== '' && value !== undefined) || r.current)
          && r.company !== ''
          && r.company !== undefined
          && r.job_title !== ''
          && r.job_title !== undefined
          && r.from !== ''
          && r.from !== undefined
        );
      case 'current':
        return (
          ((r.to !== '' && r.to !== undefined) || value)
          && r.company !== ''
          && r.company !== undefined
          && r.job_title !== ''
          && r.job_title !== undefined
          && r.from !== ''
          && r.from !== undefined
        );
      default:
        return (
          r.company !== ''
          && r.company !== undefined
          && r.job_title !== ''
          && r.job_title !== undefined
          && r.from !== ''
          && r.from !== undefined
          && ((r.to !== '' && r.to !== undefined) || r.current)
        );
    }
  };

  const setValue = (key, value, i) => {
    dispatch(
      setExperience(
        experience.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, experience[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddExperience = () => {
    dispatch(
      setExperience(
        experience.concat({
          id: undefined,
          company: '',
          job_title: '',
          from: '',
          to: '',
          current: false,
          city_id: undefined,
          state_id: undefined,
          country_id: undefined,
          description: '',
          clinical_experiences: [],
          work_type: '',
          supervisor_name: '',
          supervisor_title: '',
          supervisor_email: '',
          supervisor_phone: '',
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveExperience = (i) => {
    if (experience[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          experiences: removeData.experiences.concat(experience[i].id),
        }),
      );
    }

    dispatch(
      setExperience([...experience.slice(0, i), ...experience.slice(i + 1)]),
    );
  };

  const handlePhone = (key, value, i) => {
    dispatch(
      setExperience(
        experience.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
            };
          }
          return obj;
        }),
      ),
    );
  };

  useEffect(() => {
    const eCities = [...experienceCities];
    eCities[citiesIndex.index] = citiesIndex.cities;
    setExperienceCities(eCities);
    // eslint-disable-next-line
  }, [citiesIndex]);

  const handleState = (e, i) => {
    if (e.value !== undefined && e.value !== null) {
      dispatch(
        fetchCitiesIndex({
          countryId: experience[i].country_id,
          stateId: e.value,
          index: i,
        }),
      );

      dispatch(
        setExperience(
          experience.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                state_id: e.value,
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    const eStates = [...experienceStates];
    eStates[statesIndex.index] = statesIndex.states;
    setExperienceStates(eStates);

    const aCities = [...experienceCities];
    aCities[statesIndex.index] = [];
    setExperienceCities(aCities);
    // eslint-disable-next-line
  }, [statesIndex]);

  const handleCountry = (e, i) => {
    if (e.value !== undefined && e.value !== null) {
      dispatch(
        fetchStatesIndex({
          id: e.value,
          index: i,
        }),
      );

      dispatch(
        setExperience(
          experience.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                country_id: e.value,
                changed: true,
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);

      experience.forEach((e, i) => {
        if (
          e.country_id !== undefined
          && e.country_id !== null
          && e.country_id !== ''
        ) {
          dispatch(
            fetchStatesIndex({
              id: e.country_id,
              index: i,
            }),
          ).then(() => {
            if (
              e.state_id !== undefined
              && e.state_id !== null
              && e.state_id !== ''
            ) {
              dispatch(
                fetchCitiesIndex({
                  countryId: e.country_id,
                  stateId: e.state_id,
                  index: i,
                }),
              );
            }
          });
        }
      });
    }
  }, [dispatch, firstLoad, experience]);

  useEffect(() => {
    const docs = documents.filter((doc) => doc.document_type_id === 9);
    setAvailExperienceDocs(docs.filter(
      (ad) => !experienceDocuments.some((cd) => ad.id === cd.document.id),
    ));
  }, [documents, experienceDocuments]);

  const handleAddDocument = () => {
    dispatch(
      assignCandidateExperienceDocument({
        candidateId: uuid,
        documentId: selectedDoc,
      }),
    );
    setSelectedDoc(null);
  };

  const handleDeleteDocument = (id) => {
    dispatch(
      deleteCandidateExperienceDocument({
        candidateId: uuid,
        documentId: id,
      }),
    );
  };

  const handleUploadDocument = (files, candidateId, documentId) => {
    dispatch(uploadCandidateExperienceDocument({
      candidateId,
      documentId,
      file: files[0],
    }));
  };

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3} mb={2}>
          <Grid item md={12}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddExperience}
              >
                {t('new-experience')}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiBox pb={1} px={1}>
              {Object.keys(experience).length > 0
                && experience.map((w, i) => (
                  <div
                    key={
                      // eslint-disable-next-line react/no-array-index-key
                      `experience${i}`
                    }
                  >
                    <ExperienceCard
                      i={i}
                      e={w}
                      ops={{
                        countries,
                        states: experienceStates[i],
                        cities: experienceCities[i],
                        clinicalExperiences,
                      }}
                      setValue={setValue}
                      handleSelect={{
                        country: handleCountry,
                        states: handleState,
                        phone: handlePhone,
                      }}
                      removeFunc={handleRemoveExperience}
                    />
                    <Divider />
                  </div>
                ))}
            </SuiBox>
          </Grid>
          <Grid item md={12}>
            <SuiBox pb={1} px={1}>
              <Card sx={{ overflow: 'visible' }}>
                <CardHeader
                  action={(
                    <IconButton onClick={openDocumentMenu}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                  title={t('documents')}
                />
                <Menu
                  anchorEl={documentMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(documentMenu)}
                  onClose={closeDocumentMenu}
                  keepMounted
                >
                  <MenuItem
                    onClick={handleExpand}
                  >
                    {expandedDocumentState
                      ? t('collapse', { keyPrefix: 'common' })
                      : t('expand', { keyPrefix: 'common' })}
                  </MenuItem>
                </Menu>
                <Collapse in={expandedDocumentState} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container spacing={3}>
                      {Object.keys(availExperienceDocs).length > 0 && (
                        <>
                          <Grid item md={3} pr={1}>
                            <FormSelect
                              label={t('assign-document')}
                              placeholder={t('select-document')}
                              options={availExperienceDocs}
                              onChange={(e) => {
                                setSelectedDoc(e.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} pr={1} mt={5}>
                            <IconButton
                              aria-label="add"
                              onClick={handleAddDocument}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                          <Grid item md={6} pr={1} />
                        </>
                      )}
                      {Object.keys(experienceDocuments).length > 0
                        && experienceDocuments.map((f, j) => (
                          <Grid item md={12} pr={1}>
                            <DefaultDocumentCard
                              key={`evaluation-document-${j}`}
                              id={f.id}
                              candidateId={uuid}
                              description={f.document.description}
                              title={f.document.name}
                              fileUrl={f.file_url}
                              filesLimit={f.document.uploads_number}
                              changeFunc={(files, candidateId, docId) => {
                                handleUploadDocument(files, candidateId, docId);
                              }}
                              removeFunc={() => {
                                handleDeleteDocument(f.id);
                              }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default ExperienceInfo;
