import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setAgreementInfo } from 'reducers/agreementsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// Styles
import 'assets/styles/decoupled-editor.css';

// Functions
import { defaultValue, findSelectValue, findTemplateVariables } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  agreementInfo: state.agreement.agreementInfo,
  agreementTypes: state.common.agreementTypes,
});

function AgreementInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'agreements' });

  const {
    agreementInfo,
    agreementTypes,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [templateVariables, setTemplateVariables] = useState([]);
  const [intervalVal, setIntervalVal] = useState(1);
  const [intervalType, setIntervalType] = useState('Y');
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState({
    id: null,
    content: '',
    current: false,
    version: null,
  });
  const [loaded, setLoaded] = useState(false);
  const [content, setContent] = useState('');

  const languages = [
    {
      value: 'en',
      label: t('english'),
    },
    {
      value: 'es',
      label: t('spanish'),
    },
    {
      value: 'pt',
      label: t('portuguese'),
    },
  ];

  const interval = [
    {
      value: 'Y',
      label: t('year'),
    },
    {
      value: 'M',
      label: t('month'),
    },
    {
      value: 'D',
      label: t('day'),
    },
    {
      value: 'H',
      label: t('hour'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    const selVersion = agreementInfo.agreement_versions
      .filter((v) => v.version === agreementInfo.version)[0];

    if (selVersion !== undefined) {
      setSelectedVersion(selVersion);
      setContent(selVersion.content);
    } else {
      setSelectedVersion(agreementInfo.agreement_versions[0]);
      setContent(agreementInfo.agreement_versions[0].content);
    }

    setVersions(agreementInfo.agreement_versions
      // eslint-disable-next-line no-underscore-dangle
      .filter((v) => v._destroy !== true)
      .map((v) => ({ label: v.version, option: v.version, value: v.version })));

    if (agreementInfo.expiration !== undefined) {
      const intv = agreementInfo.expiration.split('-');
      setIntervalVal(intv[0]);
      setIntervalType(intv[1]);
    }
  }, [agreementInfo]);

  useEffect(() => {
    if (selectedVersion.content !== undefined) {
      setTemplateVariables(findTemplateVariables(selectedVersion.content));
      setContent(selectedVersion.content);
      setLoaded(true);
    }
  }, [selectedVersion]);

  const handleIntervalValue = (value) => {
    setIntervalVal(value);
    setValue('expiration', `${intervalVal}-${value}`);
  };

  const handleIntervalType = (value) => {
    setIntervalType(value);
    setValue('expiration', `${intervalVal}-${value}`);
  };

  const handleCurrentVersion = () => {
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          agreement_versions: agreementInfo.agreement_versions
            .map((v) => {
              if (v.current === true) {
                return {
                  ...v,
                  current: false,
                  changed: true,
                };
              }
              if (v.version === selectedVersion.version) {
                return {
                  ...v,
                  current: true,
                  changed: true,
                };
              }
              return v;
            }),
          version: selectedVersion.version,
          changed: true,
        },
      ),
    );
  };

  const handleSelectVersion = (version) => {
    const selVersion = agreementInfo.agreement_versions
      .filter((v) => v.version === version)[0];
    setLoaded(false);
    setSelectedVersion(selVersion);
  };

  const handleNewVersion = () => {
    const versionNum = Math.max(...agreementInfo.agreement_versions.map((v) => v.version)) + 1;
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          version: versionNum,
          agreement_versions: agreementInfo
            .agreement_versions
            .concat({
              content: selectedVersion.content,
              current: false,
              version: versionNum,
            }),
          changed: true,
        },
      ),
    );
  };

  const handleDeleteVersion = () => {
    if (selectedVersion.current) {
      Swal.fire({
        title: t('error', { keyPrefix: 'common' }),
        text: t('cannot-delete-current-version'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    } else {
      const i = agreementInfo.agreement_versions
        .findIndex((v) => v.version === selectedVersion.version);
      if (agreementInfo.agreement_versions[i].id !== undefined) {
        setValue('agreement_versions', agreementInfo.agreement_versions
          .map((v) => {
            if (v.version === selectedVersion.version) {
              return {
                ...v,
                _destroy: true,
                changed: true,
              };
            }
            return v;
          }));
      } else {
        setValue(
          'agreement_versions',
          [
            ...agreementInfo.agreement_versions.slice(0, i),
            ...agreementInfo.agreement_versions.slice(i + 1),
          ],
        );
      }
    }
  };

  const handleContent = (data) => {
    dispatch(
      setAgreementInfo(
        {
          ...agreementInfo,
          agreement_versions: agreementInfo.agreement_versions
            .map((v) => {
              if (v.version === selectedVersion.version) {
                return {
                  ...v,
                  content: data,
                  changed: true,
                };
              }
              return v;
            }),
          version: selectedVersion.version,
          changed: true,
        },
      ),
    );
  };

  return (
    <div>
      <Card id="agreement-info" sx={{ overflow: 'visible' }}>
        <SuiBox p={3}>
          <SuiTypography variant="h5">{t('agreement-info')}</SuiTypography>
        </SuiBox>
        <SuiBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item md={8}>
              <FormField
                label={t('name', { keyPrefix: 'common' })}
                value={defaultValue(agreementInfo.name)}
                onChange={(e) => (
                  setValue('name', e.target.value)
                )}
              />
            </Grid>
            <Grid item md={4}>
              <FormSelect
                label={t('agreement-type')}
                options={agreementTypes}
                value={findSelectValue(agreementTypes, agreementInfo.agreement_type_id)}
                onChange={(e) => {
                  setValue('agreement_type_id', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FormSelect
                label={t('language')}
                options={languages}
                value={findSelectValue(languages, agreementInfo.language)}
                onChange={(e) => {
                  setValue('language', e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormField
                label={t('expiration')}
                value={intervalVal}
                onChange={(e) => (
                  handleIntervalValue(e.target.value)
                )}
              />
            </Grid>
            <Grid item md={2}>
              <FormSelect
                options={interval}
                value={findSelectValue(interval, intervalType)}
                onChange={(e) => {
                  handleIntervalType(e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormSelect
                label={t('version')}
                options={versions}
                value={findSelectValue(versions, selectedVersion.version)}
                onChange={(e) => {
                  handleSelectVersion(e.value);
                }}
                sx={{
                  overflow: 'visible',
                }}
              />
            </Grid>
            <Grid item md={1}>
              <FormCheckbox
                label={t('current')}
                onClick={handleCurrentVersion}
                checked={selectedVersion.current}
              />
            </Grid>
            <Grid item md={1} mt={3}>
              <SuiButton variant="text" color="info" onClick={handleNewVersion}>
                {t('new-version')}
              </SuiButton>
            </Grid>
            <Grid item md={2} mt={3}>
              <SuiButton variant="text" color="error" onClick={handleDeleteVersion}>
                {t('delete-version')}
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <Divider />
      <Card id="agreement-info">
        <SuiBox component="form" pb={3} px={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item md={12} mt={5}>
              <div className="document-editor">
                <div className="document-editor__toolbar" />
                <div className="document-editor__editable-container">
                  <CKEditor
                    editor={DecoupledEditor}
                    data={content}
                    onReady={(editor) => {
                      window.editor = editor;
                      if (document.querySelector('.document-editor__toolbar') !== undefined
                        && document.querySelector('.document-editor__toolbar') !== null) {
                        const toolbarContainer = document.querySelector('.document-editor__toolbar');
                        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                      }
                    }}
                    onChange={(event, editor) => {
                      if (loaded) {
                        handleContent(editor.getData());
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={12}>
              <SuiTypography variant="h6">{t('template-variables')}</SuiTypography>
            </Grid>
            {Object.keys(templateVariables).length > 0
              && templateVariables.map((v, i) => (
                <Grid item xs={12} md={4} key={i}>
                  {v.replace('%{', '').replace('}', '')}
                </Grid>
              ))}
          </Grid>
        </SuiBox>
      </Card>
    </div>
  );
}

export default AgreementInfo;
