import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setAttorneyInfo } from 'reducers/attorneySlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';

// Styles
import 'assets/styles/decoupled-editor.css';

// Other components
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const selector = (state) => ({
  attorneyInfo: state.attorney.attorneyInfo,
});

function AttorneyInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'attorneys' });

  const { attorneyInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setAttorneyInfo(
        {
          ...attorneyInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="attorney-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('attorney-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name')}
              value={attorneyInfo.name !== undefined ? attorneyInfo.name : ''}
              error={!attorneyInfo.name || attorneyInfo.name === ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('description')}
            </SuiTypography>
            <div className="document-editor">
              <div className="document-editor__toolbar" />
              <CKEditor
                editor={DecoupledEditor}
                data={attorneyInfo.description}
                onReady={(editor) => {
                  window.editor = editor;
                  if (document.querySelector('.document-editor__toolbar') !== undefined
                    && document.querySelector('.document-editor__toolbar') !== null) {
                    const toolbarContainer = document.querySelector('.document-editor__toolbar');
                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                  }
                }}
                onChange={(event, editor) => {
                  if (attorneyInfo.description !== undefined) {
                    setValue('description', editor.getData());
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default AttorneyInfo;
