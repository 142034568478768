import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidateUpcomingEvents, setSaved } from 'reducers/candidatesSlice';
import { fetchAccountBalance } from 'reducers/accountsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Functions
import { getCandidateUUID } from 'Util';
import Swal from 'sweetalert2';

// Widgets page components
import UpcomingEvents from 'layouts/candidates/components/Widgets/UpcomingEvents';
import AccountBalance from 'layouts/components/Widgets/AccountBalance';
import Recruiter from 'layouts/components/Widgets/Recruiter';
import PaymentPlans from 'layouts/components/Widgets/PaymentPlans';
import CandidateSummary from 'layouts/candidates/components/Widgets/CandidateSummary';
import LastPayment from 'layouts/components/Widgets/LastPayment';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  events: state.candidate.upcoming,
  balance: state.account.balance,
  saved: state.candidate.saved,
});

function Overview() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.overview' });
  const {
    currentUser,
    editing,
    events,
    balance,
    saved,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateUpcomingEvents({ uuid }));
      dispatch(fetchAccountBalance({ id: uuid }));
    }
  }, [dispatch, editing, uuid]);

  if (saved) {
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success')}!`,
      text: t('candidate-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close'),
    });
  }

  return (
    <SuiBox mb={3}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <CandidateSummary uuid={uuid} view={currentUser.role.id === 5 ? 'recruitment' : 'default'} />
        </Grid>
        <Grid item md={4}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item md={12}>
              <PaymentPlans uuid={uuid} />
              <Divider />
            </Grid>
            <Grid item md={6}>
              <AccountBalance uuid={uuid} />
              <Divider />
              {balance.last_payment_date !== null && (
                <div>
                  <LastPayment uuid={uuid} />
                  <Divider />
                </div>
              )}
            </Grid>
            <Grid item md={6}>
              <Recruiter uuid={uuid} />
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <UpcomingEvents events={events} />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Overview;
