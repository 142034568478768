// Reducer functions
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// Functions
import { checkPermission, moveSubView, setTitle } from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import AdminGeneral from '../AdminGeneral';
import Marketing from '../Marketing';
import Bsr from '../BSR';

const selector = (state) => ({
  profile: state.candidate.profile,
  currentSubView: state.candidate.currentSubView,
  currentUser: state.auth.user,
  goToSubView: state.candidate.goToSubView,
});

function Administrative() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.administrative' });
  const {
    profile,
    currentSubView,
    currentUser,
    goToSubView,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  setTitle('Candidate Administrative');

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetAdminTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('general'):
        dispatch(setCurrentSubView('general'));
        break;
      case t('marketing'):
        dispatch(setCurrentSubView('marketing'));
        break;
      case t('bsr'):
        dispatch(setCurrentSubView('bsr'));
        break;
      default:
        dispatch(setCurrentSubView('general'));
        break;
    }
  };

  if (checkPermission('CAGE', currentUser)) {
    tabs.push(t('general'));
    views.push('general');
  }

  if (checkPermission('CAMA', currentUser)) {
    tabs.push(t('marketing'));
    views.push('marketing');
  }

  if (checkPermission('CABS', currentUser)) {
    tabs.push(t('bsr'));
    views.push('bsr');
  }

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  useEffect(() => {
    setDefaultTab(views.indexOf(currentSubView) >= 0 ? views.indexOf(currentSubView) : 0);
  }, [currentSubView, views]);

  return (
    <div id="administrative">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetAdminTabValue}
        defaultTabValue={defaultTab}
      />
      {currentSubView === 'general' && <AdminGeneral />}
      {currentSubView === 'marketing' && <Marketing />}
      {currentSubView === 'bsr' && (
        <Bsr
          uuid={profile.uuid}
          bsrUserId={profile.bsr_user_id !== undefined ? profile.bsr_user_id : null}
        />
      )}
    </div>
  );
}

export default Administrative;
