import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

// Reducer functions
import {
  createCandidateImmigration,
  fetchCandidateImmigration, setCreated, setFailed,
  setImmigration, setSaved, updateCandidateImmigration,
  updateCandidateImmigrationVisaScreen,
} from 'reducers/candidatesSlice';
import { fetchAttorneys } from 'reducers/attorneySlice';
import { fetchVisaScreenAgencies } from 'reducers/commonSlice';

// @mui material components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Components
import FormField from 'layouts/components/FormField';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';
import FormSelect from 'layouts/components/FormSelect';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import {
  failedCandidateMessage, findSelectValue,
  getCandidateUUID, NoChangesWarning, savedCandidateMessage,
  setTitle,
} from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  immigration: state.candidate.immigration,
  attorneys: state.attorney.attorneys,
  agencies: state.common.visaScreenAgencies,
});

function Immigration() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.immigration',
  });

  const {
    editing,
    created,
    saved,
    failed,
    errors,
    immigration,
    attorneys,
    agencies,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchAttorneys());
    dispatch(fetchVisaScreenAgencies());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateImmigration({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Immigration');

  const visaTypes = [
    {
      value: 1,
      label: 'EB-3',
    },
    {
      value: 2,
      label: 'HB-1',
    },
  ];

  const visaStatus = [
    {
      value: 1,
      label: t('approved'),
    },
    {
      value: 2,
      label: t('denied'),
    },
    {
      value: 3,
      label: t('review'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setImmigration({
        ...immigration,
        [key]: value,
        changed: true,
      }),
    );
  };

  const handleUploadVisaScreenFile = (files, id) => {
    dispatch(updateCandidateImmigrationVisaScreen({
      id,
      candidateId: uuid,
      file: files[0],
    }));
  };

  const handleSave = () => {
    if (uuid !== undefined && immigration.changed) {
      const data = {
        candidateId: uuid,
        i140Submission: immigration.i140_submission,
        i140Approval: immigration.i140_approval,
        visaTypeId: immigration.visa_type_id,
        visaStatusId: immigration.visa_status_id,
        embassyAppointment: immigration.embassy_appointment,
        visaScreenAgencyId: immigration.visa_screen_agency_id,
        visaScreenStart: immigration.visa_screen_start,
        visaScreenIssue: immigration.visa_screen_issue,
        visaScreenExpiration: immigration.visa_screen_expiration,
        attorneyId: immigration.attorney_id,
      };

      if (typeof immigration.id !== 'undefined') {
        data.id = immigration.id;
        dispatch(updateCandidateImmigration(data));
      } else {
        dispatch(createCandidateImmigration(data));
      }
    } else {
      NoChangesWarning(t);
    }
  };

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox p={1}>
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('immigration')}
        />
        <SuiBox p={1} mb={2}>
          <Grid container spacing={3}>
            <Grid item md={2} pr={1}>
              <FormField
                label={t('i140-submission-date')}
                type="date"
                value={immigration.i140_submission}
                onChange={(e) => {
                  setValue('i140_submission', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2} pr={1}>
              <FormField
                label={t('i140-approval-date')}
                type="date"
                value={immigration.i140_approval}
                onChange={(e) => {
                  setValue('i140_approval', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2} pr={1}>
              <FormSelect
                label={t('visa-type')}
                options={visaTypes}
                value={
                  findSelectValue(visaTypes, immigration.visa_type_id)
                }
                onChange={(event) => {
                  setValue('visa_type_id', event.value);
                }}
              />
            </Grid>
            <Grid item md={2} pr={1}>
              <FormSelect
                label={t('visa-status')}
                options={visaStatus}
                value={
                  findSelectValue(visaStatus, immigration.visa_status_id)
                }
                onChange={(e) => {
                  setValue('visa_status_id', e.value);
                }}
              />
            </Grid>
            <Grid item md={2} pr={1}>
              <FormField
                label={t('embassy-appointment')}
                type="date"
                value={immigration.embassy_appointment}
                onChange={(e) => {
                  setValue('embassy_appointment', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <Divider />
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('visa-screen')}
        />
        <SuiBox p={1} mb={2}>
          <Grid container spacing={3}>
            <Grid item md={3} pr={1}>
              <FormSelect
                label={t('agency-name')}
                options={agencies}
                value={
                  findSelectValue(agencies, immigration.visa_screen_agency_id)
                }
                onChange={(event) => {
                  setValue('visa_screen_agency_id', event.value);
                }}
              />
            </Grid>
            <Grid item md={3} pr={1}>
              <FormField
                label={t('start-date')}
                type="date"
                value={immigration.visa_screen_start}
                onChange={(e) => {
                  setValue('visa_screen_start', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={3} pr={1}>
              <FormField
                label={t('issue-date')}
                type="date"
                value={immigration.visa_screen_issue}
                onChange={(e) => {
                  setValue('visa_screen_issue', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={3} pr={1}>
              <FormField
                label={t('expiration-date')}
                type="date"
                value={immigration.visa_screen_expiration}
                onChange={(e) => {
                  setValue('visa_screen_expiration', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={12}>
              <DefaultDocumentCard
                id={immigration.id}
                candidateId={uuid}
                title={t('result-document')}
                fileUrl={immigration.visa_screen_document_url}
                filesLimit={1}
                changeFunc={(files) => {
                  handleUploadVisaScreenFile(files, immigration.id);
                }}
                expanded={false}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <Divider />
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('attorney')}
        />
        <SuiBox p={1} mb={2}>
          <Grid container spacing={3}>
            <Grid item md={3} pr={1}>
              <FormSelect
                label={t('attorney')}
                options={attorneys}
                value={
                  findSelectValue(attorneys, immigration.attorney_id)
                }
                onChange={(event) => {
                  setValue('attorney_id', event.value);
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <SuiBox mt={5} mb={3}>
        <SuiBox display="flex" justifyContent="flex-end" mt={6}>
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleSave}
            >
              {t('save', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Immigration;
