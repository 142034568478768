import Service from './service';

class AttorneyService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getAttorneys() {
    const response = await this.api
      .get(
        'attorneys',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getAttorney(id) {
    const response = await this.api
      .get(
        `attorneys/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createAttorney(name, description) {
    const data = {
      attorney: {
        name,
        description,
      },
    };

    const response = await this.api
      .post(
        'attorneys',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateAttorney(id, name, description) {
    const data = {
      attorney: {
        name,
        description,
      },
    };

    const response = await this.api
      .put(
        `attorneys/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteAttorney(id) {
    const response = await this.api
      .delete(
        `attorneys/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new AttorneyService();
