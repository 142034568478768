import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

// Reducer functions
import { setDocumentInfo } from 'reducers/documentsSlice';
import { fetchDocumentTypes } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormTextarea from 'layouts/components/FormTextarea';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSelect from 'layouts/components/FormSelect';

// Util functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  documentInfo: state.document.documentInfo,
  documentTypes: state.common.documentTypes,
});

function DocumentInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'documents' });
  const {
    documentInfo,
    documentTypes,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, [dispatch]);

  const setValue = (key, value) => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleRequired = () => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          is_required: !documentInfo.is_required,
          changed: true,
        },
      ),
    );
  };

  const handleAutoAssigned = () => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          auto_assigned: !documentInfo.auto_assigned,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="document-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('document-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormField
              label={t('name')}
              value={documentInfo.name !== undefined ? documentInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormSelect
              label={t('document-type')}
              textTransform="none"
              options={documentTypes}
              value={findSelectValue(documentTypes, documentInfo.document_type_id)}
              onChange={(e) => {
                setValue('document_type_id', e.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormField
              label={t('uploads-limit')}
              value={documentInfo.uploads_number !== undefined ? documentInfo.uploads_number : ''}
              onChange={(e) => {
                setValue('uploads_number', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSwitch
              label={`${t('required')}: ${documentInfo.is_required
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!documentInfo.is_required}
              onChange={handleRequired}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSwitch
              label={`${t('automatically-assigned')}: ${documentInfo.auto_assigned
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!documentInfo.auto_assigned}
              onChange={handleAutoAssigned}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormTextarea
              label={t('description')}
              value={documentInfo.description !== undefined ? documentInfo.description : ''}
              onChange={(e) => {
                setValue('description', e.target.value);
              }}
              minRows={10}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default DocumentInfo;
