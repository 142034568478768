import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { clearCustomer, setCustomerEditing } from 'reducers/customerSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import CustomerInfo from '../components/CustomerInfo';

function NewCustomer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCustomerEditing(false));
    dispatch(clearCustomer());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CustomerInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewCustomer;
