import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchTemplate, setSucceeded, setTemplateEditing } from 'reducers/templatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import TemplateInfo from '../components/TemplateInfo';
import SavePanel from '../components/SavePanel';

function EditTemplate() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      setTemplateEditing(true),
    );
    dispatch(
      fetchTemplate({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <TemplateInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditTemplate;
