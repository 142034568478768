import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createTemplate,
  deleteTemplate,
  setCreated,
  setFailed,
  setSaved,
  updateTemplate,
} from 'reducers/templatesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import Swal from 'sweetalert2';

const selector = (state) => ({
  templateInfo: state.template.templateInfo,
  editing: state.template.editing,
  created: state.template.created,
  saved: state.template.saved,
  failed: state.template.failed,
  errors: state.template.errors,
});

function SavePanel() {
  const {
    templateInfo,
    editing,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'templates' });

  const { id } = useParams();

  const callUpdateTemplate = async (data) => {
    await dispatch(updateTemplate(data));
  };

  const callCreateTemplate = async (data) => {
    await dispatch(createTemplate(data));
  };

  const callDeleteTemplate = async (sId) => {
    await dispatch(
      deleteTemplate({
        id: sId,
      }),
    );
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeleteTemplate(id).then(() => {
            Swal.fire(t('deleted', { keyPrefix: 'common' }), '', 'success').then(() => {
              navigate('/templates', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    if (templateInfo.changed === true) {
      const data = {
        name: templateInfo.name,
        content: templateInfo.content,
      };

      if (editing === true) {
        data.id = id;

        callUpdateTemplate(data);
      } else {
        callCreateTemplate(data);
      }
    }
  };

  if (failed === true) {
    dispatch(setFailed(false));
    const errMsg = errors.join('; ');

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: `${errMsg}`,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  if (created === true || saved === true) {
    dispatch(setCreated(false));
    dispatch(setSaved(false));

    Swal.fire({
      text: saved ? t('modified-successfully') : t('created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/templates');
    });
  }

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
