import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActions } from '@mui/material';
import Modal from '@mui/material/Modal';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import FormAutocomplete from 'layouts/components/FormAutocomplete';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Utils
import {
  defaultValue, findSelectValue, findTemplateVariables, getCandidateUUID, MissingDataWarning,
} from 'Util';

const cardStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgColor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
};

function DefaultFingerprintsCards(
  {
    i,
    f,
    ops,
    setValue,
    removeFunc,
    createDocFunc,
    uploadDocFunc,
    removeDocFunc,
    templateVars,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.fingerprints' });
  const uuid = getCandidateUUID();

  const [expanded, setExpanded] = useState(true);
  const [fingerprintsMenu, setFingerprintsMenu] = useState(null);
  const [openAssign, setOpenAssign] = useState(false);
  const [templateVariables, setTemplateVariables] = useState([]);
  const [variablesValues, setVariablesValues] = useState([]);
  const [fingerprintDocuments, setFingerprintsDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [availableDocuments, setAvailableDocuments] = useState([]);
  const [process, setProcess] = useState('');
  const [submissionDate, setSubmissionDate] = useState(null);

  useEffect(() => {
    if (f.candidate_fingerprint_documents !== undefined
      && f.candidate_fingerprint_documents !== null) {
      setFingerprintsDocuments(f.candidate_fingerprint_documents);
    }
    setAvailableDocuments(ops.documents
      .filter((d) => !fingerprintDocuments.some((cd) => d.id === cd.template.id)));
  }, [ops, f, fingerprintDocuments]);

  const carriers = [
    {
      id: 'USPS',
      label: 'USPS',
    },
    {
      id: 'FedEx',
      label: 'FedEx',
    },
    {
      id: 'UPS',
      label: 'UPS',
    },
    {
      id: 'DHL',
      label: 'DHL',
    },
  ];
  const openFingerprintsMenu = (event) => setFingerprintsMenu(event.currentTarget);
  const closeFingerprintsMenu = () => setFingerprintsMenu(null);

  const handleExpand = () => {
    closeFingerprintsMenu();
    setExpanded(!expanded);
  };

  const handleCarrier = (key, value) => {
    if (value !== undefined) {
      setValue(key, value, i);
    }
  };

  const handleClose = () => setOpenAssign(false);
  const handleOpen = () => setOpenAssign(true);

  const handleAssign = () => {
    if (selectedDocument !== undefined) {
      let isValid = true;

      templateVariables.forEach((v) => {
        const name = v.replace('%{', '').replace('}', '');
        isValid = isValid
          ? variablesValues[name] !== undefined && variablesValues[name] !== ''
          : false;
      });

      if (isValid) {
        createDocFunc(f.id, selectedDocument.id, variablesValues);
        handleClose();
      } else {
        MissingDataWarning(t);
      }
    }
  };

  const handleValidateDocument = () => {
    if (selectedDocument !== undefined) {
      const vars = findTemplateVariables(selectedDocument.content);

      setTemplateVariables(vars);

      const varVals = {};

      vars.forEach((v) => {
        const name = v.replace('%{', '').replace('}', '');
        varVals[name] = templateVars[name] !== undefined ? templateVars[name] : '';
      });

      setVariablesValues(varVals);

      handleOpen();
    }
  };

  const handleDate = (value) => {
    setSubmissionDate(value);
    setValue('submission_date', value, i);
  };

  useEffect(() => {
    if (f.state_id !== undefined && f.state_id !== null) {
      const state = findSelectValue(ops.states, f.state_id);
      if (state !== null && state.name !== undefined) {
        setProcess(state.name);
      }
    }
  }, [f, ops]);

  useEffect(() => {
    if (f.submission_date !== undefined && f.submission_date !== null) {
      setSubmissionDate(f.submission_date);
    }
  }, [f.submission_date]);

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openFingerprintsMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={process}
      />
      <Menu
        anchorEl={fingerprintsMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(fingerprintsMenu)}
        onClose={closeFingerprintsMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormSelect
                  label={t('state')}
                  placeholder={t('state')}
                  options={ops.states}
                  value={
                    ops.states !== undefined
                      ? findSelectValue(ops.states, f.state_id, i) : undefined
                  }
                  error={f.state_id ? false
                    : !f.state_id || f.state_id === 0}
                  onChange={(event) => {
                    setValue('state_id', event.value, i);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} pr={1}>
                <FormField
                  label={t('submission-date')}
                  type="date"
                  onChange={(event) => {
                    handleDate(event.target.value);
                  }}
                  value={defaultValue(submissionDate)}
                />
              </Grid>
              <Grid item xs={12} md={2} pr={1}>
                <FormAutocomplete
                  freeSolo
                  options={carriers.map((option) => option.label)}
                  label={t('carrier-name')}
                  onChange={
                    (event, newValue) => {
                      if (typeof newValue === 'string') {
                        handleCarrier('submission_carrier', newValue, i);
                      } else if (newValue && newValue.inputValue) {
                        handleCarrier('submission_carrier', newValue.inputValue, i);
                      } else {
                        handleCarrier('submission_carrier', newValue, i);
                      }
                    }
                  }
                  onKeyUp={(event) => {
                    handleCarrier('submission_carrier', event.target.value, i);
                  }}
                  value={defaultValue(f.submission_carrier)}
                />
              </Grid>
              <Grid item xs={12} md={5} pr={1}>
                <FormField
                  label={t('tracking-number')}
                  onChange={(event) => {
                    setValue('submission_tracking', event.target.value, i);
                  }}
                  value={defaultValue(f.submission_tracking)}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                {f.id !== undefined && (
                  <FormSelect
                    label={t('assign-document')}
                    placeholder={t('select-document')}
                    options={availableDocuments}
                    onChange={(e) => {
                      setSelectedDocument(findSelectValue(ops.documents, e.value));
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3} pr={1} mt={5}>
                {f.id !== undefined && (
                  <IconButton
                    aria-label="add"
                    onClick={handleValidateDocument}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={12} md={6} pr={1} />
              {Object.keys(fingerprintDocuments).length > 0
                && fingerprintDocuments.map((fd, j) => (
                  <Grid item xs={12} md={4} pr={1}>
                    <DefaultDocumentCard
                      key={j}
                      id={fd.id}
                      candidateId={uuid}
                      title={fd.template.name}
                      fileUrl={fd.file_url}
                      filesLimit={1}
                      changeFunc={(files, candidateId, docId) => {
                        uploadDocFunc(f.id, files, candidateId, docId);
                      }}
                      removeFunc={(id, candidateId) => {
                        removeDocFunc(f.id, id, candidateId);
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
      <Modal
        open={openAssign}
        onClose={handleClose}
        aria-labelledby="Validate Agreement"
        aria-describedby="Review template variables"
      >
        <Card sx={cardStyle}>
          <CardHeader title={t('variables', { keyPrefix: 'common' })} />
          <CardContent>
            <Grid container spacing={3}>
              {Object.keys(templateVariables).length > 0
                && templateVariables.map((v, j) => (
                  <Grid item xs={6} md={6} key={`variable${j}`}>
                    <FormField
                      label={v.replace('%{', '').replace('}', '')}
                      textTransform="none"
                      value={
                        variablesValues[v.replace('%{', '').replace('}', '')]
                      }
                      error={
                        variablesValues[v.replace('%{', '').replace('}', '')] === ''
                        || variablesValues[v.replace('%{', '').replace('}', '')] === undefined
                      }
                      onChange={(e) => setValue(
                        v.replace('%{', '').replace('}', ''),
                        e.target.value,
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SuiButton
                variant="gradient"
                size="small"
                onClick={handleAssign}
              >
                {t('assign', { keyPrefix: 'common' })}
              </SuiButton>
            </Grid>
          </CardActions>
        </Card>
      </Modal>
    </Card>
  );
}

DefaultFingerprintsCards.propTypes = {
  i: PropTypes.number.isRequired,
  f: PropTypes.shape(
    {
      state_id: PropTypes.number,
      submission_date: PropTypes.string,
      submission_carrier: PropTypes.string,
      submission_tracking: PropTypes.string,
      /* eslint-disable react/forbid-prop-types */
      fingerprint: PropTypes.object,
      candidate_fingerprint_documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          template: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            content: PropTypes.string,
          }),
        }),
      ),
      id: PropTypes.number,
    },
  ).isRequired,
  ops: PropTypes.shape({
    processes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    states: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  createDocFunc: PropTypes.func.isRequired,
  uploadDocFunc: PropTypes.func.isRequired,
  removeDocFunc: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  templateVars: PropTypes.object.isRequired,
};

export default DefaultFingerprintsCards;
