import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import PositionListingService from 'services/positionListing.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledDeletedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchPositionListings = createAsyncThunk(
  'positionListing/fetchPositionListings',
  async (payload, { rejectWithValue }) => {
    try {
      return await PositionListingService.getPositionListings();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPositionListing = createAsyncThunk(
  'positionListing/fetchPositionListing',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PositionListingService.getPositionListing(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPositionListing = createAsyncThunk(
  'positionListing/createPositionListing',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      description,
      customerId,
      openings,
    } = payload;
    try {
      return await PositionListingService.createPositionListing(
        name,
        description,
        customerId,
        openings,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePositionListing = createAsyncThunk(
  'positionListing/updatePositionListing',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      description,
      customerId,
      openings,
    } = payload;
    try {
      return await PositionListingService.updatePositionListing(
        id,
        name,
        description,
        customerId,
        openings,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deletePositionListing = createAsyncThunk(
  'positionListing/deletePositionListing',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PositionListingService.deletePositionListing(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const positionListingSlice = createSlice({
  name: 'positionListing',
  initialState: {
    positionListingInfo: {},
    positionListings: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearPositionListing: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.positionListingInfo = {
        name: '',
        description: '',
        customer_id: '',
        openings: '',
      };
    },
    setPositionListingEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPositionListingInfo: (state, action) => {
      state.positionListingInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'positionListing') {
          switch (performedAction[1]) {
            case 'fetchPositionListings':
              fulfilledReducer(state, action);
              state.positionListings = action.payload.map((item) => ({
                ...item,
                value: item.id,
                label: `${item.customer_name} - ${item.name}`,
              }));
              break;
            case 'fetchPositionListing':
              fulfilledReducer(state, action);
              state.positionListingInfo = action.payload;
              break;
            case 'createPositionListing':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updatePositionListing':
              fulfilledSavedReducer(state, action);
              state.positionListingInfo = action.payload.data;
              break;
            case 'deletePositionListing':
              fulfilledDeletedReducer(state, action);
              state.positionListingInfo = action.payload.data;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearPositionListing,
  setPositionListingEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setPositionListingInfo,
} = positionListingSlice.actions;

export default positionListingSlice.reducer;
