import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import CustomerService from 'services/customer.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledDeletedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchCustomers = createAsyncThunk(
  'customer/fetchCustomers',
  async (payload, { rejectWithValue }) => {
    try {
      return await CustomerService.getCustomers();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCustomer = createAsyncThunk(
  'customer/fetchCustomer',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await CustomerService.getCustomer(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (payload, { rejectWithValue }) => {
    const { name, website } = payload;
    try {
      return await CustomerService.createCustomer(name, website);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (payload, { rejectWithValue }) => {
    const { id, name, website } = payload;
    try {
      return await CustomerService.updateCustomer(id, name, website);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCustomer = createAsyncThunk(
  'customer/deleteCustomer',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await CustomerService.deleteCustomer(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customerInfo: {},
    customers: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearCustomer: (state) => {
      state.customerInfo = {
        name: '',
        website: '',
      };
      state.customers = [];
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
    },
    setCustomerEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'customer') {
          switch (performedAction[1]) {
            case 'fetchCustomers':
              fulfilledReducer(state, action);
              state.customers = generateDropboxProperties(action);
              break;
            case 'fetchCustomer':
              fulfilledReducer(state, action);
              state.customerInfo = action.payload;
              break;
            case 'createCustomer':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updateCustomer':
              fulfilledSavedReducer(state, action);
              state.customerInfo = action.payload.data;
              break;
            case 'deleteCustomer':
              fulfilledDeletedReducer(state, action);
              state.customerInfo = action.payload.data;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearCustomer,
  setCustomerEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setCustomerInfo,
} = customerSlice.actions;

export default customerSlice.reducer;
