// react components
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import { defaultValue, findSelectValue } from 'Util';
import InstitutionCollapse from './InstitutionCollapse';

// Functions

function DefaultEducationCard(
  {
    i,
    d,
    ops,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.education' });

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };
  const handleAddInstitution = () => {
    setValue(
      'education_institutions',
      d.education_institutions.concat({
        id: undefined,
        name: '',
        still_active: true,
        graduated: false,
        from: '',
        to: '',
        address: '',
        country_id: undefined,
        state_id: undefined,
        city_id: undefined,
        postal_code: '',
        valid: false,
      }),
      i,
    );
  };

  const setValueInstitution = (value, idx) => {
    const institutions = d.education_institutions.map((obj, index) => {
      if (index === idx) {
        return value;
      }
      return obj;
    });

    if (value.graduated === true) {
      setValue(
        'education_institutions',
        institutions.map((obj, index) => {
          if (index === idx) {
            return {
              ...obj,
              graduated: true,
            };
          }
          return {
            ...obj,
            graduated: false,
          };
        }),
        i,
      );
    } else {
      setValue(
        'education_institutions',
        institutions,
        i,
      );
    }
  };

  const handleRemoveInstitution = (j) => {
    if (d.education_institutions[j].id !== undefined) {
      setValueInstitution({
        ...d.education_institutions[j],
        _destroy: true,
      }, j);
    } else {
      setValue(
        'education_institutions',
        [...d.education_institutions.slice(0, j), ...d.education_institutions.slice(j + 1)],
        i,
      );
    }
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={d.degree !== '' ? d.degree : `${t('degree')} ${i + 1}`}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <FormField
                  key={`degree-${i}`}
                  label={t('degree-obtained')}
                  error={!d.degree || d.degree === ''}
                  onChange={(e) => {
                    setValue('degree', e.target.value, i);
                  }}
                  value={defaultValue(d.degree)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormSelect
                  label={t('education-level')}
                  options={ops.educationLevels}
                  id={`education_level[${i}]`}
                  error={!d.education_level_id || d.education_level_id === ''}
                  value={findSelectValue(ops.educationLevels, d.education_level_id, i)}
                  onChange={(e) => {
                    setValue('education_level_id', e.value, i);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormField
                  label={t('graduation-date')}
                  type="date"
                  placeholder="To"
                  error={!d.graduation || d.graduation === ''}
                  onChange={(e) => {
                    setValue('graduation', e.target.value, i);
                  }}
                  value={defaultValue(d.graduation)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField
                  label={t('name-on-diploma-if-different')}
                  onChange={(e) => {
                    setValue('name_under', e.target.value, i);
                  }}
                  value={defaultValue(d.name_under)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <SuiBox display="flex" justifyContent="flex-end">
                  <SuiButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleAddInstitution}
                  >
                    {t('new-school')}
                  </SuiButton>
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={12}>
                {Object.keys(d.education_institutions).length > 0
                  && d.education_institutions.map((u, j) => (
                    <div
                      key={`school${i}${j}`}
                      style={{
                        // eslint-disable-next-line no-underscore-dangle
                        display: (u._destroy !== undefined ? 'none' : 'block'),
                        paddingBottom: '10px',
                      }}
                    >
                      <InstitutionCollapse
                        i={i}
                        j={j}
                        u={u}
                        ops={{
                          countries: ops.countries,
                        }}
                        setValue={setValueInstitution}
                        removeFunc={handleRemoveInstitution}
                      />
                    </div>
                  ))}
              </Grid>
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultEducationCard.propTypes = {
  i: PropTypes.number.isRequired,
  d: PropTypes.shape(
    {
      degree: PropTypes.string,
      education_level_id: PropTypes.number,
      graduation: PropTypes.string,
      name_under: PropTypes.string,
      id: PropTypes.number,
      education_institutions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          from: PropTypes.string,
          to: PropTypes.string,
          still_active: PropTypes.bool,
          address: PropTypes.string,
          country_id: PropTypes.number,
          state_id: PropTypes.number,
          city_id: PropTypes.number,
          postal_code: PropTypes.string,
        }),
      ),
      university: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      still_active: PropTypes.bool,
      address: PropTypes.string,
      country_id: PropTypes.number,
      state_id: PropTypes.number,
      city_id: PropTypes.number,
      postal_code: PropTypes.string,
    },
  ).isRequired,
  ops: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    educationLevels: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultEducationCard;
