import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers functions
import { setCustomerInfo } from 'reducers/customerSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import { useTranslation } from 'react-i18next';

const selector = (state) => ({
  customerInfo: state.customer.customerInfo,
});

function CustomerInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'customers' });

  const { customerInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setCustomerInfo(
        {
          ...customerInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="customer-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('customer-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name')}
              value={customerInfo.name !== undefined ? customerInfo.name : ''}
              error={!customerInfo.name || customerInfo.name === ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('website')}
              value={customerInfo.website !== undefined ? customerInfo.website : ''}
              onChange={(e) => {
                setValue('website', e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default CustomerInfo;
