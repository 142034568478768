import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearCustomer,
  createCustomer,
  deleteCustomer,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateCustomer,
} from 'reducers/customerSlice';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  customerInfo: state.customer.customerInfo,
  editing: state.customer.editing,
  deleted: state.customer.deleted,
  created: state.customer.created,
  saved: state.customer.saved,
  updated: state.customer.updated,
  failed: state.customer.failed,
  errors: state.customer.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'customers' });

  const {
    customerInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearCustomer());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('customer-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/customers', { replace: true });
    });
  }

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearCustomer());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('customer-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/customers', { replace: true });
    });
  }

  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearCustomer({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('customer-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/customers', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (customerInfo.changed === true) {
      if (customerInfo.name !== undefined && customerInfo.name !== '') {
        const data = {
          name: customerInfo.name,
          website: customerInfo.website,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updateCustomer(data));
        } else {
          dispatch(createCustomer(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${customerInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteCustomer(data));
      }
    });
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
