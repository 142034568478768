import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Reducer functions
import { setTemplateInfo } from 'reducers/templatesSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// Styles
import 'assets/styles/decoupled-editor.css';

// Functions
import { findTemplateVariables, setTitle } from 'Util';

const selector = (state) => ({
  templateInfo: state.template.templateInfo,
});

function TemplateInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'templates' });
  const { templateInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Templates');

  const [templateVariables, setTemplateVariables] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const setValue = (key, value) => {
    dispatch(
      setTemplateInfo(
        {
          ...templateInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const getTemplateVariable = () => {
    if (templateInfo.content !== undefined) {
      setTemplateVariables(findTemplateVariables(templateInfo.content));
    }
  };

  useEffect(() => {
    if (loaded) {
      if (templateInfo.content !== undefined) {
        getTemplateVariable();
      }
    }

    if (templateInfo.name !== undefined
      && !loaded) {
      setLoaded(true);
      getTemplateVariable();
    }
    // eslint-disable-next-line
  }, [templateInfo]);

  return (
    <Card id="template-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('template-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name', { keyPrefix: 'common' })}
              value={templateInfo.name !== undefined ? templateInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="document-editor">
              <div className="document-editor__toolbar" />
              <div className="document-editor__editable-container">
                <CKEditor
                  editor={DecoupledEditor}
                  data={templateInfo.content}
                  onReady={(editor) => {
                    window.editor = editor;
                    if (document.querySelector('.document-editor__toolbar') !== undefined
                      && document.querySelector('.document-editor__toolbar') !== null) {
                      const toolbarContainer = document.querySelector('.document-editor__toolbar');
                      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                    }
                  }}
                  onChange={(event, editor) => {
                    if (templateInfo.content !== undefined) {
                      setValue('content', editor.getData());
                    }
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography variant="h6">{t('template-variables')}</SuiTypography>
          </Grid>
          {Object.keys(templateVariables).length > 0
            && templateVariables.map((v, i) => (
              <Grid item xs={12} md={4} key={i}>
                {v.replace('%{', '').replace('}', '')}
              </Grid>
            ))}
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default TemplateInfo;
