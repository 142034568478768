import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import {
  assignCandidateEvaluationUniversityDoc,
  deleteCandidateEvaluationUniversityDoc,
  uploadCandidateEvaluationUniversityDoc,
} from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import FormAutocomplete from 'layouts/components/FormAutocomplete';
import DefaultUniversityDocsCard from 'layouts/components/Cards/UniversityDocsCards/DefaultUniversityDocsCard';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Utils
import { defaultValue, findSelectValue, getCandidateUUID } from 'Util';

function DefaultCredentialCard(
  {
    i,
    e,
    ops,
    setValue,
    removeFunc,
    evalDocs,
    uniDocs,
    licDocs,
    trasnDocs,
    veriSubm,
    resDocs,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.credentials-evaluation' });
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const licenseSubmissionMethods = [
    {
      value: 1,
      label: t('email', { keyPrefix: 'common' }),
    },
    {
      value: 2,
      label: t('mail', { keyPrefix: 'common' }),
    },
  ];

  const carriers = [
    {
      id: 'USPS',
      label: 'USPS',
    },
    {
      id: 'FedEx',
      label: 'FedEx',
    },
    {
      id: 'UPS',
      label: 'UPS',
    },
    {
      id: 'DHL',
      label: 'DHL',
    },
  ];

  const [expanded, setExpanded] = useState(true);
  const [credentialEvaluationMenu, setCredentialEvaluationMenu] = useState(null);
  const [evaluator, setEvaluator] = useState('');
  const [process, setProcess] = useState('');
  const [expandedEvaluationDocs, setExpandedEvaluationDocs] = useState(true);
  const [expandedUniversityDocs, setExpandedUniversityDocs] = useState(true);
  const [expandedTranslationDocs, setExpandedTranslationDocs] = useState(true);
  const [expandedLicenseDocs, setExpandedLicenseDocs] = useState(true);
  const [expandedResultDocs, setExpandedResultDocs] = useState(true);
  const [evaluationDocsCardMenu, setEvaluationDocsCardMenu] = useState(null);
  const [universityDocsCardMenu, setUniversityDocsCardMenu] = useState(null);
  const [licenseDocsCardMenu, setLicenseDocsCardMenu] = useState(null);
  const [translationDocsCardMenu, setTranslationDocsCardMenu] = useState(null);
  const [resultDocsCardMenu, setResultDocsCardMenu] = useState(null);
  const [evaluationDocs, setEvaluationDocs] = useState([]);
  const [assignedEvaluationDocs, setAssignedEvaluationDocs] = useState([]);
  const [licenseDocs, setLicenseDocs] = useState([]);
  const [assignedLicenseDocs, setAssignedLicenseDocs] = useState([]);
  const [translationDocs, setTranslationDocs] = useState([]);
  const [assignedTranslationDocs, setAssignedTranslationDocs] = useState([]);
  const [resultDocs, setResultDocs] = useState([]);
  const [assignedResultDocs, setAssignedResultDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const openEvaluationDocsCardMenu = (event) => setEvaluationDocsCardMenu(event.currentTarget);
  const closeEvaluationDocsCardMenu = () => setEvaluationDocsCardMenu(null);
  const handleEvaluationDocsExpand = () => {
    closeEvaluationDocsCardMenu();
    setExpandedEvaluationDocs(!expandedEvaluationDocs);
  };
  const openUniversityDocsCardMenu = (event) => setUniversityDocsCardMenu(event.currentTarget);
  const closeUniversityDocsCardMenu = () => setUniversityDocsCardMenu(null);
  const handleUniversityDocsExpand = () => {
    closeUniversityDocsCardMenu();
    setExpandedUniversityDocs(!expandedUniversityDocs);
  };
  const openLicenseDocsCardMenu = (event) => setLicenseDocsCardMenu(event.currentTarget);
  const closeLicenseDocsCardMenu = () => setLicenseDocsCardMenu(null);
  const handleLicenseDocsExpand = () => {
    closeLicenseDocsCardMenu();
    setExpandedLicenseDocs(!expandedLicenseDocs);
  };
  const openTranslationDocsCardMenu = (event) => setTranslationDocsCardMenu(event.currentTarget);
  const closeTranslationDocsCardMenu = () => setTranslationDocsCardMenu(null);
  const handleTranslationDocsExpand = () => {
    closeTranslationDocsCardMenu();
    setExpandedTranslationDocs(!expandedTranslationDocs);
  };
  const openResultDocsCardMenu = (event) => setResultDocsCardMenu(event.currentTarget);
  const closeResultDocsCardMenu = () => setResultDocsCardMenu(null);
  const handleResultDocsExpand = () => {
    closeResultDocsCardMenu();
    setExpandedResultDocs(!expandedResultDocs);
  };

  const openCredentialMenu = (event) => setCredentialEvaluationMenu(event.currentTarget);
  const closeCredentialMenu = () => setCredentialEvaluationMenu(null);
  const handleExpand = () => {
    closeCredentialMenu();
    setExpanded(!expanded);
  };

  const setValueUniversityDocs = (key, value, j) => {
    setValue(
      'university_docs',
      e.university_docs.map((obj, index) => {
        if (index === j) {
          return {
            ...obj,
            [key]: value,
          };
        }
        return obj;
      }),
      i,
    );
  };

  const handleLicense = (ev) => {
    ev.preventDefault();
    setValue('license_docs_submitted', !e.license_docs_submitted, i);
  };

  const handleTranslation = (ev) => {
    ev.preventDefault();
    setValue('translation_submitted', !e.translation_submitted, i);
  };

  const handleUniversityDocsVerified = (ev) => {
    ev.preventDefault();
    setValue('university_docs_verified', !e.university_docs_verified, i);
  };

  const handleLicenseDocsVerified = (ev) => {
    ev.preventDefault();
    setValue('license_verified', !e.license_verified, i);
  };

  const handleCarrier = (key, value, j) => {
    if (value !== undefined) {
      setValueUniversityDocs(key, value, j);
    }
  };

  const handleAddUniversityDocs = () => {
    setValue(
      'university_docs',
      e.university_docs.concat({
        id: undefined,
        submitted: false,
        submission_method_id: undefined,
        submission_date: '',
        submission_carrier: '',
        submission_tracking: '',
        files: [],
      }),
      i,
    );
  };

  const handleRemoveUniversityDocs = (j) => {
    if (e.university_docs[j].id !== undefined) {
      setValue(
        'university_docs',
        e.university_docs.map((obj, index) => {
          if (index === j) {
            return {
              ...obj,
              _destroy: true,
              destroy: true,
            };
          }
          return obj;
        }),
        i,
      );
    } else {
      setValue(
        'university_docs',
        [...e.university_docs.slice(0, j), ...e.university_docs.slice(j + 1)],
        i,
      );
    }
  };

  const handleAddDocument = (evn, documentType) => {
    evn.preventDefault();

    dispatch(
      assignCandidateEvaluationUniversityDoc({
        id: e.id,
        candidateId: uuid,
        typeId: documentType,
        documentId: selectedDoc,
      }),
    );
  };

  const handleUploadDocument = (files, candidateId, documentId, documentType) => {
    dispatch(
      uploadCandidateEvaluationUniversityDoc({
        id: e.id,
        candidateId,
        typeId: documentType,
        documentId,
        file: files[0],
      }),
    );
  };

  const handleRemoveDocument = (documentId, candidateId, documentType) => {
    dispatch(
      deleteCandidateEvaluationUniversityDoc({
        id: e.id,
        candidateId,
        typeId: documentType,
        documentId,
      }),
    );
  };

  useEffect(() => {
    if (e.credential_evaluator_id !== undefined && e.credential_evaluator_id !== null) {
      const eVal = findSelectValue(ops.evaluators, e.credential_evaluator_id);
      if (eVal !== null && eVal !== undefined) {
        setEvaluator(eVal.name !== undefined
          ? eVal.name : '');
      }
    }

    if (e.process_type_id !== undefined && e.process_type_id !== null) {
      const ptVal = findSelectValue(ops.processTypes, e.process_type_id);
      if (ptVal !== undefined && ptVal !== null) {
        setProcess(ptVal.name !== undefined
          ? ptVal.name : '');
      }
    }

    if (ops.documents !== undefined && ops.documents !== null) {
      const allEvaluationDocs = ops.documents.filter((doc) => doc.document_type_id === 3);
      const assgEvaluationDocs = e.evaluation_documents
        .filter((d) => d.document.document_type_id === 3);
      const allLicenseDocs = ops.documents.filter((doc) => doc.document_type_id === 4);
      const assgLicenseDocs = e.evaluation_documents
        .filter((d) => d.document.document_type_id === 4);
      const allTranslationDocs = ops.documents.filter((doc) => doc.document_type_id === 5);
      const assgTranslationDocs = e.evaluation_documents
        .filter((d) => d.document.document_type_id === 5);
      const allResultDocs = ops.documents.filter((doc) => doc.document_type_id === 6);
      const assgResultDocs = e.evaluation_documents
        .filter((d) => d.document.document_type_id === 6);

      setAssignedEvaluationDocs(assgEvaluationDocs);
      setAssignedLicenseDocs(assgLicenseDocs);
      setAssignedTranslationDocs(assgTranslationDocs);
      setAssignedResultDocs(assgResultDocs);

      setEvaluationDocs(allEvaluationDocs.filter(
        (ad) => !assgEvaluationDocs.some((cd) => ad.id === cd.document.id),
      ));

      setLicenseDocs(allLicenseDocs.filter(
        (ad) => !assgLicenseDocs.some((cd) => ad.id === cd.document.id),
      ));

      setTranslationDocs(allTranslationDocs.filter(
        (ad) => !assgTranslationDocs.some((cd) => ad.id === cd.document.id),
      ));

      setResultDocs(allResultDocs.filter(
        (ad) => !assgResultDocs.some((cd) => ad.id === cd.document.id),
      ));
    }
  }, [e, ops]);

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openCredentialMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${evaluator} -
        ${process}`}
      />
      <Menu
        anchorEl={credentialEvaluationMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(credentialEvaluationMenu)}
        onClose={closeCredentialMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              <Grid item md={5}>
                <FormSelect
                  label={t('evaluator')}
                  placeholder={t('evaluator')}
                  options={ops.evaluators}
                  id={`evaluators[${i}]`}
                  value={
                    ops.evaluators !== undefined
                      ? findSelectValue(ops.evaluators, e.credential_evaluator_id) : undefined
                  }
                  error={e.credential_evaluator_id ? false
                    : !e.credential_evaluator_id || e.credential_evaluator_id === ''}
                  onChange={(event) => {
                    setValue('credential_evaluator_id', event.value, i);
                  }}
                />
              </Grid>
              <Grid item md={2} pr={1}>
                <FormField
                  label={t('started-date')}
                  type="date"
                  error={e.started_date ? false
                    : !e.started_date || e.started_date === ''}
                  onChange={(event) => {
                    setValue('started_date', event.target.value, i);
                  }}
                  value={defaultValue(e.started_date)}
                />
              </Grid>
              <Grid item md={2} pr={1}>
                <FormField
                  label={t('expiration-date')}
                  type="date"
                  error={e.expiration_date ? false
                    : !e.expiration_date || e.expiration_date === ''}
                  onChange={(event) => {
                    setValue('expiration_date', event.target.value, i);
                  }}
                  value={defaultValue(e.expiration_date)}
                />
              </Grid>
              {evalDocs && (
                <Grid item md={12} pr={1}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openEvaluationDocsCardMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={`${t('evaluation-documents')}`}
                    />
                    <Menu
                      anchorEl={evaluationDocsCardMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(evaluationDocsCardMenu)}
                      onClose={closeEvaluationDocsCardMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleEvaluationDocsExpand}
                      >
                        {expandedEvaluationDocs
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <SuiBox p={1}>
                      <Collapse in={expandedEvaluationDocs} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                          {(Object.keys(evaluationDocs).length > 0 && e.id !== undefined) && (
                            <>
                              <Grid item md={3} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={evaluationDocs}
                                  onChange={(evn) => {
                                    setSelectedDoc(evn.value);
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={(evn) => {
                                    handleAddDocument(evn, 3);
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item md={6} pr={1} />
                            </>
                          )}
                          {Object.keys(assignedEvaluationDocs).length > 0
                            && assignedEvaluationDocs.map((f, j) => (
                              <Grid item xs={12} md={4} pr={1}>
                                <DefaultDocumentCard
                                  key={`evaluation-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files, candidateId, docId) => {
                                    handleUploadDocument(files, candidateId, docId, 3);
                                  }}
                                  removeFunc={(id, candidateId) => {
                                    handleRemoveDocument(id, candidateId, 3);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Collapse>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {uniDocs && (
                <Grid item md={12} pr={1}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openUniversityDocsCardMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={`${t('university-documents')}`}
                    />
                    <Menu
                      anchorEl={universityDocsCardMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(universityDocsCardMenu)}
                      onClose={closeUniversityDocsCardMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleUniversityDocsExpand}
                      >
                        {expandedUniversityDocs
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <SuiBox p={1}>
                      <Collapse in={expandedUniversityDocs} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item>
                              <SuiButton
                                variant="gradient"
                                size="small"
                                onClick={handleAddUniversityDocs}
                              >
                                {t('new-submission')}
                              </SuiButton>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} pr={1}>
                            {(e.university_docs !== undefined && e.university_docs !== null)
                              && (Object.keys(e.university_docs).length > 0
                                && e.university_docs.map((d, j) => (
                                  <div
                                    style={{
                                      display: (d.destroy !== undefined ? 'none' : 'block'),
                                      paddingBottom: '10px',
                                    }}
                                    key={`universityDocs${j}`}
                                  >
                                    <DefaultUniversityDocsCard
                                      i={j}
                                      uuid={uuid}
                                      d={d}
                                      ops={{
                                        carriers,
                                        documents: ops.documents
                                          .filter((doc) => doc.document_type_id === 2),
                                      }}
                                      setValue={setValueUniversityDocs}
                                      removeFunc={handleRemoveUniversityDocs}
                                    />
                                  </div>
                                )))}
                          </Grid>
                        </Grid>
                      </Collapse>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {licDocs && (
                <Grid item md={12} pr={1}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openLicenseDocsCardMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={`${t('license-documents')}`}
                    />
                    <Menu
                      anchorEl={licenseDocsCardMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(licenseDocsCardMenu)}
                      onClose={closeLicenseDocsCardMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleLicenseDocsExpand}
                      >
                        {expandedLicenseDocs
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <SuiBox p={1}>
                      <Collapse in={expandedLicenseDocs} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                          <Grid item md={5} pr={1}>
                            <FormSwitch
                              label={`${t('license-documents')} ${t('submitted')}: ${e.license_docs_submitted
                                ? t('yes', { keyPrefix: 'common' })
                                : t('no', { keyPrefix: 'common' })}`}
                              checked={!!e.license_docs_submitted}
                              onChange={handleLicense}
                            />
                          </Grid>
                          <Grid item md={7} />
                          <Grid item md={2} pr={1}>
                            {(e.license_docs_submitted) && (
                              <FormSelect
                                label={t('submission-method')}
                                placeholder={t('submission-method')}
                                options={licenseSubmissionMethods}
                                id={`license-submission-methods[${i}]`}
                                value={
                                  licenseSubmissionMethods !== undefined
                                    ? findSelectValue(
                                      licenseSubmissionMethods,
                                      e.license_docs_submission_method_id,
                                      i,
                                    )
                                    : undefined
                                }
                                error={e.license_docs_submission_method_id ? false
                                  : !e.license_docs_submission_method_id || e.license_docs_submission_method_id === ''}
                                onChange={(event) => {
                                  setValue('license_docs_submission_method_id', event.value, i);
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item md={2} pr={1}>
                            {(e.license_docs_submitted) && (
                              <FormField
                                label={t('submission-date')}
                                type="date"
                                error={e.license_docs_submission_date ? false
                                  : !e.license_docs_submission_date || e.license_docs_submission_date === ''}
                                onChange={(event) => {
                                  setValue('license_docs_submission_date', event.target.value, i);
                                }}
                                value={defaultValue(e.license_docs_submission_date)}
                              />
                            )}
                          </Grid>
                          <Grid item md={3} pr={1}>
                            {(e.license_docs_submitted
                              && e.license_docs_submission_method_id === 2) && (
                              <FormAutocomplete
                                freeSolo
                                options={carriers.map((option) => option.label)}
                                label={t('carrier-name')}
                                onChange={
                                  (event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      handleCarrier('license_docs_submission_carrier', newValue);
                                    } else if (newValue && newValue.inputValue) {
                                      handleCarrier('license_docs_submission_carrier', newValue.inputValue);
                                    } else {
                                      handleCarrier('license_docs_submission_carrier', newValue);
                                    }
                                  }
                                }
                                onKeyUp={(event) => {
                                  handleCarrier('license_docs_submission_carrier', event.target.value, i);
                                }}
                                value={defaultValue(e.license_docs_submission_carrier)}
                              />
                            )}
                          </Grid>
                          <Grid item md={4} pr={1}>
                            {(e.license_docs_submitted
                              && e.license_docs_submission_method_id === 2) && (
                              <FormField
                                label={t('tracking-number')}
                                error={!e.license_docs_submission_tracking || e.license_docs_submission_tracking === ''}
                                onChange={(event) => {
                                  setValue('license_docs_submission_tracking', event.target.value, i);
                                }}
                                value={defaultValue(e.license_docs_submission_tracking)}
                              />
                            )}
                          </Grid>
                          <Grid item md={1} />
                          {(e.id !== undefined && Object.keys(licenseDocs).length > 0) && (
                            <>
                              <Grid item md={3} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={licenseDocs}
                                  onChange={(evn) => {
                                    setSelectedDoc(evn.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3} pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={(evn) => {
                                    handleAddDocument(evn, 4);
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} md={6} pr={1} />
                            </>
                          )}
                          {Object.keys(assignedLicenseDocs).length > 0
                            && assignedLicenseDocs.map((f, j) => (
                              <Grid item xs={12} md={4} pr={1}>
                                <DefaultDocumentCard
                                  key={`license-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files, candidateId, docId) => {
                                    handleUploadDocument(files, candidateId, docId, 4);
                                  }}
                                  removeFunc={(id, candidateId) => {
                                    handleRemoveDocument(id, candidateId, 4);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Collapse>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {trasnDocs && (
                <Grid item md={12} pr={1}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openTranslationDocsCardMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={`${t('translation-documents')}`}
                    />
                    <Menu
                      anchorEl={translationDocsCardMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(translationDocsCardMenu)}
                      onClose={closeTranslationDocsCardMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleTranslationDocsExpand}
                      >
                        {expandedTranslationDocs
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <SuiBox p={1}>
                      <Collapse in={expandedTranslationDocs} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                          <Grid item md={5} pr={1}>
                            <FormSwitch
                              label={`${t('translation')} ${t('submitted')}: ${e.translation_submitted
                                ? t('yes', { keyPrefix: 'common' })
                                : t('no', { keyPrefix: 'common' })}`}
                              checked={!!e.translation_submitted}
                              onChange={handleTranslation}
                            />
                          </Grid>
                          <Grid item md={2} pr={1}>
                            {(e.translation_submitted) && (
                              <FormField
                                label={t('submission-date')}
                                type="date"
                                error={e.translation_submission_date ? false
                                  : !e.translation_submission_date || e.translation_submission_date === ''}
                                onChange={(event) => {
                                  setValue('translation_submission_date', event.target.value, i);
                                }}
                                value={defaultValue(e.translation_submission_date)}
                              />
                            )}
                          </Grid>
                          <Grid item md={5} />
                          {(e.id !== undefined && Object.keys(translationDocs).length > 0) && (
                            <>
                              <Grid item md={3} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={translationDocs}
                                  onChange={(evn) => {
                                    setSelectedDoc(evn.value);
                                  }}
                                />
                              </Grid>
                              <Grid item pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={(evn) => {
                                    handleAddDocument(evn, 5);
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} md={6} pr={1} />
                            </>
                          )}
                          {Object.keys(assignedTranslationDocs).length > 0
                            && assignedTranslationDocs.map((f, j) => (
                              <Grid item xs={12} md={4} pr={1}>
                                <DefaultDocumentCard
                                  key={`translation-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files, candidateId, docId) => {
                                    handleUploadDocument(files, candidateId, docId, 3);
                                  }}
                                  removeFunc={(id, candidateId) => {
                                    handleRemoveDocument(id, candidateId, 3);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Collapse>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              <Divider />
              {veriSubm && (
                <>
                  <Grid item md={6} pr={1}>
                    <FormSwitch
                      label={`${t('license-documents-verified')} ${t('submitted')}: ${e.license_verified
                        ? t('yes', { keyPrefix: 'common' })
                        : t('no', { keyPrefix: 'common' })}`}
                      checked={!!e.license_verified}
                      onChange={handleLicenseDocsVerified}
                    />
                  </Grid>
                  <Grid item md={2} pr={1}>
                    {(e.license_verified) && (
                      <FormField
                        label={t('verification-date')}
                        type="date"
                        error={e.license_verification_date ? false
                          : !e.license_verification_date || e.license_verification_date === ''}
                        onChange={(event) => {
                          setValue('license_verification_date', event.target.value, i);
                        }}
                        value={defaultValue(e.license_verification_date)}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} pr={1} />
                  <Grid item md={6} pr={1}>
                    <FormSwitch
                      label={`${t('university-documents-verified')} ${t('submitted')}: ${e.university_docs_verified
                        ? t('yes', { keyPrefix: 'common' })
                        : t('no', { keyPrefix: 'common' })}`}
                      checked={!!e.university_docs_verified}
                      onChange={handleUniversityDocsVerified}
                    />
                  </Grid>
                  <Grid item md={2} pr={1}>
                    {(e.university_docs_verified) && (
                      <FormField
                        label={t('verification-date')}
                        type="date"
                        error={e.university_docs_verification_date ? false
                          : !e.university_docs_verification_date || e.university_docs_verification_date === ''}
                        onChange={(event) => {
                          setValue('university_docs_verification_date', event.target.value, i);
                        }}
                        value={defaultValue(e.university_docs_verification_date)}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} pr={1} />
                </>
              )}
              {resDocs && (
                <Grid item md={12} pr={1}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openResultDocsCardMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={`${t('result-documents')}`}
                    />
                    <Menu
                      anchorEl={resultDocsCardMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(resultDocsCardMenu)}
                      onClose={closeResultDocsCardMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleResultDocsExpand}
                      >
                        {expandedResultDocs
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <SuiBox p={1}>
                      <Collapse in={expandedResultDocs} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                          <Grid item md={4} pr={1}>
                            <FormSelect
                              label={t('evaluation-result')}
                              placeholder={t('evaluation-result')}
                              options={ops.processTypes}
                              id={`type[${i}]`}
                              value={
                                findSelectValue(ops.processTypes, e.process_type_id)
                              }
                              error={e.process_type_id ? false
                                : !e.process_type_id || e.process_type_id === ''}
                              onChange={(event) => {
                                setValue('process_type_id', event.value, i);
                              }}
                            />
                          </Grid>
                          <Grid item md={2} pr={1}>
                            <FormField
                              label={t('ces-issue-date')}
                              type="date"
                              onChange={(event) => {
                                setValue('ces_issue_date', event.target.value, i);
                              }}
                              value={defaultValue(e.ces_issue_date)}
                            />
                          </Grid>
                          <Grid item md={6} />
                          {(e.id !== undefined && Object.keys(resultDocs).length > 0) && (
                            <>
                              <Grid item md={3} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={resultDocs}
                                  onChange={(evn) => {
                                    setSelectedDoc(evn.value);
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={(evn) => {
                                    handleAddDocument(evn, 6);
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item md={6} pr={1} />
                            </>
                          )}
                          {Object.keys(assignedResultDocs).length > 0
                            && assignedResultDocs.map((f, j) => (
                              <Grid item xs={12} md={4} pr={1}>
                                <DefaultDocumentCard
                                  key={`result-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files, candidateId, docId) => {
                                    handleUploadDocument(files, candidateId, docId, 6);
                                  }}
                                  removeFunc={(id, candidateId) => {
                                    handleRemoveDocument(id, candidateId, 6);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Collapse>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              <Grid item md={5} pr={1} />
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultCredentialCard.defaultProps = {
  evalDocs: true,
  uniDocs: true,
  licDocs: true,
  trasnDocs: true,
  veriSubm: true,
  resDocs: true,
};

DefaultCredentialCard.propTypes = {
  i: PropTypes.number.isRequired,
  e: PropTypes.shape(
    {
      credential_evaluator_id: PropTypes.number,
      process_type_id: PropTypes.number,
      started_date: PropTypes.string,
      expiration_date: PropTypes.string,
      university_docs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          submitted: PropTypes.bool,
          submission_method_id: PropTypes.number,
          submission_date: PropTypes.string,
          submission_tracking: PropTypes.string,
          submission_carrier: PropTypes.string,
        }),
      ),
      license_docs_submitted: PropTypes.bool,
      license_docs_submission_method_id: PropTypes.number,
      license_docs_submission_date: PropTypes.string,
      license_docs_submission_tracking: PropTypes.string,
      license_docs_submission_carrier: PropTypes.string,
      translation_submitted: PropTypes.bool,
      translation_submission_date: PropTypes.string,
      university_docs_verified: PropTypes.bool,
      university_docs_verification_date: PropTypes.string,
      license_verified: PropTypes.bool,
      license_verification_date: PropTypes.string,
      ces_issue_date: PropTypes.string,
      id: PropTypes.number,
      evaluation_documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            uploads_number: PropTypes.number,
          }),
        }),
      ),
    },
  ).isRequired,
  ops: PropTypes.shape({
    evaluators: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    processTypes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  evalDocs: PropTypes.bool,
  uniDocs: PropTypes.bool,
  licDocs: PropTypes.bool,
  trasnDocs: PropTypes.bool,
  veriSubm: PropTypes.bool,
  resDocs: PropTypes.bool,
};

export default DefaultCredentialCard;
